import { useState } from 'react';

export enum ERROR {
	POPUP_BLOCKER_ERROR = 'Please disable your popup blocker to view the PDF.',
	PDF_DOWNLOAD_ERROR = 'An error occurred while downloading the PDF.',
}

interface UseDownloadPdfProps {
	fetchPdfData: () => Promise<Blob>;
	onError?: (error: ERROR) => void;
}

interface DownloadPdfActionProps {
	onSuccess?: () => void;
	onError?: (error: ERROR) => void;
}

export function useDownloadPdf({ fetchPdfData }: UseDownloadPdfProps) {
	const [isLoading, setIsLoading] = useState(false);
	const [error, setError] = useState<string>();

	const downloadPdfAction = async (props?: DownloadPdfActionProps) => {
		setIsLoading(true);

		try {
			const pdfData = await fetchPdfData();
			const blob = new Blob([pdfData], { type: 'application/pdf' });
			const blobUrl = URL.createObjectURL(blob);
			const newWindow = window.open(blobUrl);

			if (newWindow === null) {
				props?.onError?.(ERROR.POPUP_BLOCKER_ERROR);
				setError(ERROR.POPUP_BLOCKER_ERROR);
			} else {
				newWindow.addEventListener('unload', () => URL.revokeObjectURL(blobUrl));
				props?.onSuccess?.();
			}
		} catch (err) {
			props?.onError?.(ERROR.PDF_DOWNLOAD_ERROR);
			setError(ERROR.PDF_DOWNLOAD_ERROR);
		} finally {
			setIsLoading(false);
		}
	};

	return {
		isLoading,
		error,
		downloadPdfAction,
	};
}

export default useDownloadPdf;
