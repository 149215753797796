import { useQuery } from '@tanstack/react-query';
import { medsProcessingJobs } from 'core/api/admin/patients.api';
import { MedsProcessingJobParams } from 'core/api/admin/patients.models';

export function useMedsProcessingJobsQuery({ page, patientId, ...restRequest }: MedsProcessingJobParams) {
	return useQuery({
		queryKey: ['admin', 'patient', 'view', patientId, 'medsProcessingJobs', restRequest, page],
		queryFn: () => medsProcessingJobs({ page, patientId, ...restRequest }),
		enabled: !!patientId,
	});
}

export default useMedsProcessingJobsQuery;
