import React, { useMemo, useState, useRef } from 'react';
import CircleIcon from '@mui/icons-material/Circle';
import DataGrid from 'components/dataGrid';
import { Paper, PaperTitle } from 'components/dashboardLayout';
import { GridColDef } from '@mui/x-data-grid';
import { Stack } from '@mui/material';
import { usePatientInboxQuery } from 'hooks/enterprise';
import { MessageSummary } from 'components/inbox';
import StatusChip, { StatusChipLegend } from 'components/StatusChip';
import { InboxStatus } from 'core/api/inboxNotification';
import relativeDateFormat from 'utils/relativeDateFormat';

interface InboxListProps {
	patientId: string;
	onClickNotification: (notificationId: string) => void;
}

export function InboxList({ patientId, onClickNotification }: InboxListProps) {
	const [paginationModel, setPaginationModel] = useState({
		page: 0,
		pageSize: 25,
	});
	const { data, isLoading } = usePatientInboxQuery({
		patientId,
		page: paginationModel.page + 1,
		itemsPerPage: paginationModel.pageSize,
	});
	const { member: inboxData, totalItems: inboxTotal } = data ?? {};

	const rowCountRef = useRef(inboxTotal || 0);
	const rowCount = useMemo(() => {
		if (inboxTotal !== undefined) {
			rowCountRef.current = inboxTotal;
		}
		return rowCountRef.current;
	}, [inboxTotal]);

	const statusLegend = useMemo<StatusChipLegend[]>(
		() => [
			{
				id: InboxStatus.Inbox,
				label: 'Inbox',
				color: 'primary',
			},
			{
				id: InboxStatus.Deleted,
				label: 'Deleted',
				color: 'error',
			},
		],
		[]
	);

	const columns: GridColDef[] = [
		{ field: 'id', headerName: 'ID', sortable: false, width: 80 },
		{
			field: 'subject',
			headerName: 'Subject',
			sortable: false,
			flex: 3,
			renderCell: ({ row, value }) => (
				<Stack direction="row" gap={2} onClick={() => onClickNotification(row.id)} sx={{ cursor: 'pointer' }}>
					{!row.readAt && <CircleIcon sx={{ fontSize: 12, color: (theme) => theme.palette.primary.main }} />}
					<MessageSummary primary={value} secondary={row.preview} />
				</Stack>
			),
		},
		{
			field: 'statusId',
			headerName: 'Status',
			sortable: false,
			flex: 1,
			display: 'flex',
			renderCell: ({ value }) => <StatusChip legend={statusLegend} statusId={value} />,
		},
		{
			field: 'readAt',
			headerName: 'Read At',
			sortable: false,
			flex: 1,
			renderCell: ({ value }) => relativeDateFormat(value) || 'N/A',
		},
		{
			field: 'createdAt',
			headerName: 'Created At',
			sortable: false,
			flex: 1,
			renderCell: ({ value }) => relativeDateFormat(value) || 'N/A',
		},
	];

	return (
		<Paper>
			<Stack direction="row" justifyContent="space-between" alignItems="flex-start">
				<PaperTitle>Inbox ({rowCount})</PaperTitle>
			</Stack>
			<DataGrid
				autoHeight
				rows={inboxData || []}
				columns={columns}
				loading={isLoading}
				rowCount={rowCount}
				pageSizeOptions={[5, 10, 25]}
				paginationModel={paginationModel}
				onPaginationModelChange={setPaginationModel}
				paginationMode="server"
				disableRowSelectionOnClick
				disableColumnFilter
				columnBufferPx={8}
				getRowHeight={() => 'auto'}
			/>
		</Paper>
	);
}

export default InboxList;
