import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { deleteLoginAttempt } from 'core/api/admin/blockedLogins.api';
import { LoginAttemptCollectionRequest } from 'core/api/admin/blockedLogins.models';
import { loginAttemptQueries } from 'queries/admin';

export function useLoginAttempt() {
	const queryClient = useQueryClient();

	const loginAttemptListQuery = (props: LoginAttemptCollectionRequest) => {
		return useQuery(loginAttemptQueries.loginAttemptList(props));
	};

	const deleteLoginAttemptMutation = useMutation({
		mutationFn: deleteLoginAttempt,
		onSettled: () => {
			queryClient.invalidateQueries({
				queryKey: ['admin', 'loginAttempt', 'list'],
			});
		},
	});

	return {
		loginAttemptListQuery,
		deleteLoginAttemptMutation,
	};
}

export default useLoginAttempt;
