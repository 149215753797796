import { queryOptions } from '@tanstack/react-query';
import {
	patientDetails,
	patientMedications,
	patientMedicationDetails,
	patientDeliveryDetails,
	patientDeliveriesList,
	patientInbox,
	patientInboxNotification,
	asNeededMedsList,
	patientMedHistory,
	scheduledMedsList,
} from 'core/api/enterprise/patient.api';
import {
	EntPatientAsNeededMedsParams,
	EntPatientMedHistoryParams,
	EntPatientScheduledMedsParams,
	PatientDeliveriesListParams,
	PatientDeliveryDetailsParams,
	PatientDetailsParams,
	PatientInboxNotificationParams,
	PatientInboxParams,
	PatientMedicationParams,
	PatientMedicationsParams,
} from 'core/api/enterprise/patient.models';

export const enterprisePatientQueries = {
	patient: ({ id }: Partial<PatientDetailsParams>) =>
		queryOptions({
			queryKey: ['enterprise', 'patient', id, 'details'],
			queryFn: () => patientDetails({ id: id || '' }),
			enabled: !!id,
		}),
	medications: ({ patientId }: Partial<PatientMedicationsParams>) =>
		queryOptions({
			queryKey: ['enterprise', 'patient', patientId, 'medications'],
			queryFn: () => patientMedications({ patientId: patientId || '' }),
			enabled: !!patientId,
		}),
	medication: ({ patientId, sfid }: Partial<PatientMedicationParams>) =>
		queryOptions({
			queryKey: ['enterprise', 'patient', patientId, 'medication', sfid],
			queryFn: () => patientMedicationDetails({ patientId: patientId || '', sfid: sfid || '' }),
			enabled: !!patientId && !!sfid,
		}),
	deliveries: ({ patientId }: Partial<PatientDeliveriesListParams>) =>
		queryOptions({
			queryKey: ['enterprise', 'patient', patientId, 'deliveries'],
			queryFn: () => patientDeliveriesList({ patientId: patientId || '' }),
			enabled: !!patientId,
		}),
	delivery: ({ patientId, sfid }: Partial<PatientDeliveryDetailsParams>) =>
		queryOptions({
			queryKey: ['enterprise', 'patient', patientId, 'delivery', sfid],
			queryFn: () => patientDeliveryDetails({ patientId: patientId || '', sfid: sfid || '' }),
			enabled: !!patientId && !!sfid,
		}),
	inbox: ({ patientId, page, ...restRequest }: PatientInboxParams) =>
		queryOptions({
			queryKey: ['enterprise', 'patient', patientId, 'inbox', 'list', restRequest, page],
			queryFn: () => patientInbox({ patientId, page, ...restRequest }),
			refetchInterval: 2000,
			refetchIntervalInBackground: true,
			refetchOnWindowFocus: true,
			enabled: !!patientId,
		}),
	inboxNotification: ({ patientId, notificationId }: PatientInboxNotificationParams) =>
		queryOptions({
			queryKey: ['enterprise', 'patient', patientId, 'inbox', 'message', notificationId],
			queryFn: () => patientInboxNotification({ patientId: patientId || '', notificationId: notificationId || '' }),
			enabled: !!patientId && !!notificationId,
		}),
	asNeededMedsList: ({ patientId, day }: EntPatientAsNeededMedsParams) =>
		queryOptions({
			queryKey: ['enterprise', 'patient', patientId, 'asNeededMed', 'list', day],
			queryFn: () => asNeededMedsList({ patientId: patientId || '', day }),
			enabled: !!patientId && !!day,
		}),
	patientMedHistory: ({ patientId, ...dateRange }: EntPatientMedHistoryParams) =>
		queryOptions({
			queryKey: ['enterprise', 'patient', patientId, 'schedule', 'history', dateRange],
			queryFn: () => patientMedHistory({ patientId: patientId || '', ...dateRange }),
			enabled: !!patientId,
		}),
	scheduledMedsList: ({ patientId, day }: EntPatientScheduledMedsParams) =>
		queryOptions({
			queryKey: ['enterprise', 'patient', patientId, 'schedule', 'list', day],
			queryFn: () => scheduledMedsList({ patientId: patientId || '', day }),
			enabled: !!patientId,
		}),
};
