import { useMutation } from '@tanstack/react-query';
import { ISuccessResponse } from 'core/api/api-service.models';
import { sendEmail } from 'core/api/login/login.api';
import { ISendEmailRequest } from 'core/api/login/login.models';
import { IApiError } from 'core/errors/ApiError';

export function useRequestResetPasswordMutation() {
	return useMutation<ISuccessResponse, IApiError<Record<keyof ISendEmailRequest, string>>, ISendEmailRequest, unknown>({
		mutationKey: ['requestResetPassword'],
		mutationFn: sendEmail,
	});
}

export default useRequestResetPasswordMutation;
