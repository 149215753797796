import React from 'react';
import { Typography } from '@mui/material';
import ContentCard from 'components/card/ContentCard';
import { useDeliveries } from 'hooks/patient';
import { useParams } from 'react-router-dom';
import Suspense from 'components/Suspense';
import { DeliveriesList } from '../deliveriesList';

export function DeliveriesSidebar() {
	const { getDeliveriesQuery } = useDeliveries();
	const { data: deliveriesList, isLoading: isLoadingDeliveriesList } = getDeliveriesQuery();
	const { sfid: activeSfidDelivery } = useParams();

	const deliveriesPlaceholder =
		deliveriesList?.past && deliveriesList.past.length > 0
			? 'Your next medication delivery will appear here when it is almost ready to leave our facility.'
			: 'A list of your deliveries will appear here. You will see them when they are getting ready to ship. You will be able to click on each one to get more info.';

	return (
		<ContentCard
			title="Deliveries"
			sx={{
				maxWidth: '464px',
			}}
		>
			<Typography color="text.secondary">Choose an order below to see the details and what is included.</Typography>
			<Suspense isLoading={isLoadingDeliveriesList}>
				<DeliveriesList
					items={deliveriesList?.upcoming}
					hasOrderItems={(deliveriesList?.upcoming && deliveriesList.upcoming.length > 0) || false}
					title="Upcoming Orders"
					placeholder={deliveriesPlaceholder}
					activeOrder={activeSfidDelivery}
				/>
				{deliveriesList?.past && deliveriesList.past.length > 0 && (
					<DeliveriesList
						activeOrder={activeSfidDelivery}
						items={deliveriesList?.past}
						hasOrderItems
						title="Order History"
					/>
				)}
			</Suspense>
		</ContentCard>
	);
}

export default DeliveriesSidebar;
