import React, { useCallback, useMemo, useState, useRef } from 'react';
import { Button, Grid } from '@mui/material';
import { GridActionsCellItem, GridColDef } from '@mui/x-data-grid';
import EditIcon from '@mui/icons-material/Edit';
import LoginIcon from '@mui/icons-material/Login';
import DeleteIcon from '@mui/icons-material/Delete';
import { PaperTitle, Paper } from 'components/dashboardLayout';
import { FiltersBar, SearchFilter } from 'components/common/inputs/filters';
import DataGrid from 'components/dataGrid';
import CaregiverStatusChip from 'components/caregiverStatusChip';
import BooleanChip from 'components/booleanChip';
import { useParams } from 'react-router-dom';
import useDialog from 'hooks/useDialog';
import useAuth from 'hooks/useAuth';
import ConfirmationDialog from 'components/confirmationDialog';
import { useCareTeamMemberListQuery, useCareTeamMemberMutation } from 'hooks/admin';
import EnterpriseCaregiverFormDialog from 'components/admin/EnterpriseCaregiverFormDialog';
import withPageContext from 'hoc/withPageContext';

function EnterpriseCaregiverListPage() {
	const { enterpriseId } = useParams();
	const [name, setName] = useState<string | undefined>(undefined);
	const [paginationModel, setPaginationModel] = useState({
		page: 0,
		pageSize: 25,
	});
	const { data, isLoading } = useCareTeamMemberListQuery({
		enterpriseId: Number(enterpriseId),
		page: paginationModel.page + 1,
		itemsPerPage: paginationModel.pageSize,
		name,
	});
	const { member: careTeamMembersData, totalItems: careTeamMembersTotal } = data ?? {};

	const rowCountRef = useRef(careTeamMembersTotal || 0);
	const rowCount = useMemo(() => {
		if (careTeamMembersTotal !== undefined) {
			rowCountRef.current = careTeamMembersTotal;
		}
		return rowCountRef.current;
	}, [careTeamMembersTotal]);

	const { deleteCareTeamMember } = useCareTeamMemberMutation();

	const [isOpenCTMFormDialog, openCTMFormDialog, closeCTMFormDialog, userIdToEdit] = useDialog<number>();
	const [isOpenDeleteConfirmationDialog, openDeleteConfirmationDialog, closeDeleteConfirmationDialog, userIdToDelete] =
		useDialog<number>();

	const { switchToCareTeamMember } = useAuth();
	const { mutate: switchUserMutate, isPending: isPendingSwitchUser } = switchToCareTeamMember;

	const handleDeleteCTM = useCallback(() => {
		if (userIdToDelete) {
			deleteCareTeamMember.mutate(
				{ id: userIdToDelete.toString() },
				{ onSuccess: () => closeDeleteConfirmationDialog() }
			);
		}
	}, [deleteCareTeamMember, userIdToDelete]);

	const handleClearFilters = useCallback(() => {
		setName(undefined);
	}, []);

	const columns = useMemo<GridColDef[]>(
		() => [
			{ field: 'id', headerName: 'ID', sortable: false, flex: 1 },
			{
				field: 'name',
				headerName: 'Name',
				sortable: false,
				flex: 4,
			},
			{
				field: 'email',
				headerName: 'Email',
				sortable: false,
				flex: 4,
			},
			{
				field: 'phone',
				headerName: 'Phone',
				sortable: false,
				flex: 2,
			},
			{
				field: 'supervisor',
				headerName: 'Supervisor',
				sortable: false,
				flex: 2,
				renderCell: ({ value }) => <BooleanChip value={value} />,
			},
			{
				field: 'status',
				headerName: 'Status',
				sortable: false,
				flex: 2,
				renderCell: ({ value }) => <CaregiverStatusChip status={value} />,
			},
			{
				field: 'lastUpdated',
				headerName: 'Last Updated',
				sortable: false,
				flex: 2,
			},
			{
				field: 'actions',
				type: 'actions',
				headerName: 'Actions',
				cellClassName: 'actions',
				flex: 3,
				getActions: ({ row }) => {
					return [
						<GridActionsCellItem
							icon={<LoginIcon />}
							label="Log in as"
							title="Log in as"
							color="inherit"
							disabled={isPendingSwitchUser}
							onClick={() => switchUserMutate({ id: row.userId })}
						/>,
						<GridActionsCellItem
							onClick={() => openCTMFormDialog(row.id)}
							icon={<EditIcon />}
							label="Edit"
							title="Edit"
							className="textPrimary"
							color="inherit"
						/>,
						<GridActionsCellItem
							onClick={() => openDeleteConfirmationDialog(row.id)}
							icon={<DeleteIcon />}
							label="Delete"
							title="Delete"
							color="inherit"
						/>,
					];
				},
			},
		],
		[]
	);

	return (
		<Grid item xs={12}>
			<Paper>
				<PaperTitle>Caregivers list</PaperTitle>
				<FiltersBar onClearFilters={handleClearFilters} buttonProps={{ variant: 'outlined' }}>
					<SearchFilter
						sx={{ flex: 4 }}
						name="search"
						label="Search caregiver"
						value={name}
						onChange={(event) => setName(event.target.value)}
						variant="outlined"
						size="small"
					/>
					<Button onClick={() => openCTMFormDialog()} sx={{ alignContent: 'right' }} variant="outlined">
						New Care team
					</Button>
				</FiltersBar>
				<DataGrid
					autoHeight
					rows={careTeamMembersData || []}
					columns={columns}
					loading={isLoading}
					rowCount={rowCount}
					pageSizeOptions={[5, 10, 25]}
					paginationModel={paginationModel}
					paginationMode="server"
					onPaginationModelChange={setPaginationModel}
					disableRowSelectionOnClick
					disableColumnSelector
					disableColumnMenu
					disableColumnFilter
				/>
				{enterpriseId && (
					<EnterpriseCaregiverFormDialog
						enterpriseId={enterpriseId}
						careTeamMemberId={userIdToEdit?.toString()}
						open={isOpenCTMFormDialog}
						onClose={closeCTMFormDialog}
					/>
				)}
				<ConfirmationDialog
					isLoading={deleteCareTeamMember.isPending}
					title="Delete care team member"
					content="Are you sure you want to delete this care team member?"
					open={isOpenDeleteConfirmationDialog}
					onConfirm={handleDeleteCTM}
					onCancel={closeDeleteConfirmationDialog}
				/>
			</Paper>
		</Grid>
	);
}

export default withPageContext(EnterpriseCaregiverListPage);
