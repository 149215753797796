import { useQuery } from '@tanstack/react-query';
import { patientFilterList } from 'core/api/enterprise/patient.api';

export function usePatientFiltersQuery() {
	return useQuery({
		queryKey: ['enterprise', 'patient', 'filters'],
		queryFn: () => patientFilterList(),
	});
}

export default usePatientFiltersQuery;
