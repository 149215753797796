import React from 'react';
import DynamicForm, { IDynamicFormElement } from 'components/common/inputs/dynamicForm/DynamicForm';
import Joi from 'joi';
import { IAppError } from 'core/errors/AppError';
import { ICreateAdmins } from 'core/models/admin/admins.models';
import { Alert } from '@mui/material';
import { IApiError } from 'core/errors/ApiError';

interface AdminCreateProps {
	isLoading: boolean;
	error: IAppError | null;
	onSubmit: (data: ICreateAdmins) => void;
}

export function AdminCreate({ isLoading, error, onSubmit }: AdminCreateProps) {
	const formElements: Map<string, IDynamicFormElement> = new Map()
		.set('email', {
			label: 'Email',
			type: 'email',
			validation: Joi.string()
				.email({ tlds: { allow: false } })
				.required(),
		})
		.set('password', {
			label: 'Password',
			type: 'password',
			validation: Joi.string().required(),
		})
		.set('phoneNumber', {
			label: 'Phone Number',
			type: 'text',
			validation: Joi.string().required(),
		});

	return (
		<>
			{error?.message && (
				<Alert severity="error" sx={{ mb: 2 }}>
					{error.message}
				</Alert>
			)}
			<DynamicForm
				formElements={formElements}
				formRequestIsLoading={isLoading}
				formRequestError={error as IApiError}
				onSubmitFormHandler={onSubmit}
			/>
		</>
	);
}

export default AdminCreate;
