import { endpoints } from 'core/api/endpoints';
import { deleteRequest, getRequest } from 'core/api/api-service';
import {
	DeleteLoginAttemptRequest,
	LoginAttemptCollection,
	LoginAttemptCollectionRequest,
} from './blockedLogins.models';

export async function getLoginAttemptCollection(
	request: LoginAttemptCollectionRequest
): Promise<LoginAttemptCollection> {
	const body = {
		page: request.page,
		itemsPerPage: request.itemsPerPage,
		username: request?.username || '',
	};

	return getRequest<LoginAttemptCollection>(endpoints.admin.blockedLogins.list, body, {});
}

export async function deleteLoginAttempt(request: DeleteLoginAttemptRequest): Promise<void> {
	await deleteRequest<void>(endpoints.admin.blockedLogins.delete(request.id), {}, {});
}
