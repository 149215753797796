import React from 'react';
import { get, FieldErrors, FieldName } from 'react-hook-form';
import Alert from '@mui/material/Alert';

export type FieldValuesFromFieldErrors<TFieldErrors> =
	TFieldErrors extends FieldErrors<infer TFieldValues> ? TFieldValues : never;

interface FormErrorMessagesProps<TFieldErrors> {
	errors?: FieldErrors;
	name?: FieldName<FieldValuesFromFieldErrors<TFieldErrors>>;
}

function FormErrorMessages<TFieldErrors extends FieldErrors>({
	errors,
	name,
	...rest
}: FormErrorMessagesProps<TFieldErrors>) {
	const error = name ? get(errors, name) : errors;

	if (!error) {
		return null;
	}

	if (error?.message) {
		return <Alert severity="error">{error.message}</Alert>;
	}

	return Object.entries(error as FieldErrors).map(([key, subError]) => (
		<FormErrorMessages errors={subError as FieldErrors} key={key} {...rest} />
	));
}

export { FormErrorMessages };
