import React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export function PrintIcon(props: SvgIconProps) {
	return (
		<SvgIcon viewBox="0 0 36 36" {...props}>
			<path
				d="M27.1123 27.225H32.7373C33.5248 27.225 34.0873 26.55 34.0873 25.875V13.275C34.0873 12.4875 33.4123 11.925 32.7373 11.925H29.9248"
				stroke="currentColor"
				strokeWidth="2"
				strokeMiterlimit="10"
			/>
			<path
				d="M6.1874 11.925H3.3749C2.5874 11.925 2.0249 12.6 2.0249 13.275V25.875C2.0249 26.6625 2.6999 27.225 3.3749 27.225H8.9999"
				stroke="currentColor"
				strokeWidth="2"
				strokeMiterlimit="10"
			/>
			<path d="M27.1125 4.94995H9V18.9H27.1125V4.94995Z" stroke="currentColor" strokeWidth="2" strokeMiterlimit="10" />
			<path
				d="M27.1125 23.7375H9V30.7125H27.1125V23.7375Z"
				stroke="currentColor"
				strokeWidth="2"
				strokeMiterlimit="10"
			/>
			<path d="M4.7251 18.8999H31.2751" stroke="currentColor" strokeWidth="2" strokeMiterlimit="10" />
			<path d="M5.51221 21.7124V23.0624" stroke="currentColor" strokeWidth="2" strokeMiterlimit="10" />
			<path d="M6.1875 16.7625V8.4375" stroke="currentColor" strokeWidth="2" strokeMiterlimit="10" />
			<path d="M29.9248 16.7625V8.4375" stroke="currentColor" strokeWidth="2" strokeMiterlimit="10" />
		</SvgIcon>
	);
}
