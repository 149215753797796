import { useQuery } from '@tanstack/react-query';
import { careTeamMemberList } from 'core/api/enterprise/supervisor.api';
import { CareTeamMemberListParams } from 'core/api/enterprise/supervisor.models';

export function useCareTeamMemberListQuery({ page, ...restRequest }: CareTeamMemberListParams) {
	return useQuery({
		queryKey: ['enterprise', 'careTeamMember', 'list', restRequest, page],
		queryFn: () => careTeamMemberList({ page, ...restRequest }),
	});
}

export default useCareTeamMemberListQuery;
