import { useQuery } from '@tanstack/react-query';
import { getInboxNotifications, InboxNotificationListParams } from 'core/api/inboxNotification';
import { useMemo } from 'react';
import extractQueryParam from 'utils/extractQueryParam';

export function useInboxNotificationsQuery({ page, ...restRequest }: InboxNotificationListParams) {
	const queryInfo = useQuery({
		queryKey: ['inbox', 'list', restRequest, page],
		queryFn: () => getInboxNotifications({ page, ...restRequest }),
		refetchInterval: 30000,
		refetchIntervalInBackground: true,
		enabled: true,
		refetchOnWindowFocus: true,
	});

	const lastPage = useMemo(() => {
		if (queryInfo.data?.view?.last) {
			return Number(extractQueryParam(queryInfo.data?.view?.last, 'page'));
		}
		return undefined;
	}, [queryInfo.data?.view?.last]);

	return {
		isLoading: queryInfo.isLoading,
		messages: queryInfo.data?.member || [],
		totalMessages: queryInfo.data?.totalItems || 0,
		lastPage,
	};
}

export default useInboxNotificationsQuery;
