import { useQuery } from '@tanstack/react-query';
import { patientList } from 'core/api/enterprise/patient.api';
import { PatientListParams } from 'core/api/enterprise/patient.models';

export function usePatientListQuery({ page, ...restRequest }: PatientListParams) {
	return useQuery({
		queryKey: ['enterprise', 'patient', 'list', restRequest, page],
		queryFn: () => patientList({ page, ...restRequest }),
	});
}

export default usePatientListQuery;
