import { IApiError } from 'core/errors/ApiError';
import { UseFormSetError, FieldValues, Path } from 'react-hook-form';

export const setFormErrors = <T extends FieldValues>(
	error: IApiError<Record<keyof T, string>>,
	setError: UseFormSetError<T>,
	type = 'server'
) => {
	if (!error?.violations) {
		setError('root.serverError', {
			type,
			message: error.message || 'Something went wrong',
		});
	}

	if (error?.violations) {
		Object.entries(error.violations).forEach(([field, message]) => {
			setError(field as Path<T>, {
				type,
				message,
			});
		});
	}
};

export default setFormErrors;
