import { queryOptions } from '@tanstack/react-query';
import { getLoginAttemptCollection } from 'core/api/admin/blockedLogins.api';
import { LoginAttemptCollectionRequest } from 'core/api/admin/blockedLogins.models';

export const loginAttemptQueries = {
	loginAttemptList: ({ page, ...restRequest }: LoginAttemptCollectionRequest) =>
		queryOptions({
			queryKey: ['admin', 'loginAttempt', 'list', restRequest, page],
			queryFn: () => getLoginAttemptCollection({ page, ...restRequest }),
		}),
};
