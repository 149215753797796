import React, { useEffect, useState } from 'react';
import { Alert, Button, Box, Typography } from '@mui/material';
import Joi from 'joi';
import { Controller, useForm } from 'react-hook-form';
import { joiResolver } from '@hookform/resolvers/joi';
import { buildPasswordSchema } from 'utils/buildPasswordSchema';
import { useQueryClient } from '@tanstack/react-query';
import { useChangePasswordMutation } from 'hooks';
import { AuthLayout, AuthSidebar } from 'components/auth';
import ImageCover from 'components/ImageCover';
import PasswordField from 'components/common/inputs/passwordField/PasswordField';
import { IChangePasswordRequest } from 'core/api/userAccount/userAccount.models';

const schema = Joi.object({
	password: Joi.string().required().messages({
		'string.empty': 'Current password cannot be empty',
		'any.required': 'Current password is required',
	}),
	newPassword: buildPasswordSchema(),
	repeatNewPassword: Joi.string().required().valid(Joi.ref('newPassword')).messages({
		'string.empty': 'Repeat new password cannot be empty',
		'any.required': 'Repeat new password is required',
		'any.only': 'New password and repeat new password must match',
	}),
});

interface ExpiredPasswordForm extends IChangePasswordRequest {
	repeatNewPassword: string;
}

function ExpiredPasswordScreen() {
	const redirectTime = 10;
	const [redirectCountDown, setRedirectCountDown] = useState(redirectTime);
	const queryClient = useQueryClient();
	const {
		control,
		handleSubmit,
		formState: { isValid },
	} = useForm<ExpiredPasswordForm>({ mode: 'onChange', resolver: joiResolver(schema) });
	const {
		mutate: changePasswordMutate,
		isPending,
		isSuccess,
		error: changePasswordError,
	} = useChangePasswordMutation();
	const handleSubmitExpiredPasswordForm = (formData: IChangePasswordRequest) => changePasswordMutate(formData);

	useEffect(() => {
		if (isSuccess) {
			if (redirectCountDown > 0) {
				const timer = setTimeout(() => setRedirectCountDown(redirectCountDown - 1), 1000);
				return () => clearTimeout(timer);
			}

			queryClient.invalidateQueries({ queryKey: ['user'] });
		}

		return () => {};
	}, [redirectCountDown, isSuccess]);

	return (
		<AuthLayout>
			<AuthSidebar>
				{isSuccess && (
					<Box>
						<Typography component="p" mt={15} mb={3}>
							<Typography component="strong" variant="small" color={(theme) => theme.palette.success.main}>
								Thank you for updating your password.
							</Typography>
						</Typography>
						<Box mb={12}>
							<Typography component="span" variant="input2" color="text.secondary">
								{redirectCountDown > 0
									? `You will be redirected to your ExactCare dashboard in ${redirectCountDown} seconds.`
									: `Redirecting now...`}
							</Typography>
						</Box>
					</Box>
				)}
				{!isSuccess && (
					<>
						<Typography component="h1" variant="h1" fontWeight="bold">
							Password Expired
						</Typography>
						<Typography component="span" variant="small" sx={{ color: 'text.secondary' }}>
							ExactCare security guidelines require you to change your password every 12 months.
						</Typography>
						<Typography component="strong" variant="small" sx={{ color: 'text.secondary' }}>
							Please change your password to continue.
						</Typography>

						<Box component="form" onSubmit={handleSubmit(handleSubmitExpiredPasswordForm)} mt={2}>
							{changePasswordError?.message && (
								<Alert severity="error" sx={{ mb: 2 }}>
									{changePasswordError.message}
								</Alert>
							)}
							<Box>
								<Typography sx={{ display: 'block' }} variant="input2" fontWeight={700}>
									Current Password
								</Typography>
								<Box>
									<Controller
										name="password"
										control={control}
										render={({ field, fieldState: { error } }) => (
											<PasswordField
												{...field}
												value={field.value || ''}
												autoComplete="current-password"
												helperText={error?.message}
												error={!!error}
												label="Current password"
												variant="outlined"
												size="small"
												disableTooltip
												fullWidth
											/>
										)}
									/>
								</Box>
							</Box>
							<Box mt={2}>
								<Typography sx={{ display: 'block' }} variant="input2" fontWeight={700}>
									Enter New Password
								</Typography>
								<Box>
									<Controller
										name="newPassword"
										control={control}
										render={({ field, fieldState: { error } }) => (
											<PasswordField
												{...field}
												value={field.value || ''}
												label="New password"
												variant="outlined"
												size="small"
												fullWidth
												autoComplete="new-password"
												helperText={error?.message}
												error={!!error}
											/>
										)}
									/>
								</Box>
							</Box>
							<Box mt={2}>
								<Typography sx={{ display: 'block' }} variant="input2" fontWeight={700}>
									Repeat New Password
								</Typography>
								<Box>
									<Controller
										name="repeatNewPassword"
										control={control}
										render={({ field, fieldState: { error } }) => (
											<PasswordField
												{...field}
												value={field.value || ''}
												label="Repeat new password"
												variant="outlined"
												size="small"
												fullWidth
												autoComplete="new-password"
												helperText={error?.message}
												error={!!error}
												disableTooltip
											/>
										)}
									/>
								</Box>
							</Box>
							<Box mt={2} mb={2}>
								<Button disabled={!isValid && isPending} variant="contained" color="primary" fullWidth type="submit">
									{isPending ? 'Loading...' : 'Save'}
								</Button>
							</Box>
						</Box>
					</>
				)}
			</AuthSidebar>
			<ImageCover
				src="/images/auth-seniors.png"
				sx={{
					display: { sm: 'none', md: 'flex' },
					flexGrow: 2,
				}}
			/>
		</AuthLayout>
	);
}

export default ExpiredPasswordScreen;
