import { queryOptions } from '@tanstack/react-query';
import { getPatientsListN, patientDetails, userMetaDataList } from 'core/api/admin/patients.api';
import { IPatientsListRequest, PatientDetailsParams, UserMetaDataParams } from 'core/api/admin/patients.models';

export const adminPatientQueries = {
	patientsList: ({ page, ...restRequest }: IPatientsListRequest) =>
		queryOptions({
			queryKey: ['admin', 'patients', 'list', restRequest, page],
			queryFn: () => getPatientsListN({ page, ...restRequest }),
		}),
	patient: ({ id }: Partial<PatientDetailsParams>) =>
		queryOptions({
			queryKey: ['admin', 'patient', 'view', id],
			queryFn: () => patientDetails({ id: id || '' }),
			enabled: !!id,
		}),
	userMetaDataList: (request: UserMetaDataParams) =>
		queryOptions({
			queryKey: ['admin', 'patient', 'view', request.userId, 'userMetaData'],
			queryFn: () => userMetaDataList(request),
			enabled: !!request.userId,
		}),
};
