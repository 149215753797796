import React, { useEffect, useMemo } from 'react';
import { Button, Card, CardHeader, CardContent, Stack, CardActions } from '@mui/material';
import dayjs from 'dayjs';
import Joi from 'joi';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { joiResolver } from '@hookform/resolvers/joi';
import TimePicker from 'components/common/inputs/TimePicker';
import { IAsNeededMedsTaken } from 'core/api/mySchedule/mySchedule.models';

interface AsNeededMedByDayListItemProps {
	name: string;
	date: string;
	asNeededMedsTaken: IAsNeededMedsTaken[];
	onChangeTime: (data: IAsNeededMedsTaken) => void;
	onDeleteTime: (data: IAsNeededMedsTaken) => void;
}

const timeListSchema = Joi.object({
	times: Joi.array().items(
		Joi.object({
			scheduledMedTakenId: Joi.number().required(),
			time: Joi.string().required(),
			date: Joi.string().required(),
		})
	),
});

export function AsNeededMedByDayListItem({
	name,
	date,
	asNeededMedsTaken,
	onChangeTime,
	onDeleteTime,
}: AsNeededMedByDayListItemProps) {
	const { control, getValues, reset } = useForm({
		mode: 'onChange',
		resolver: joiResolver(timeListSchema),
		defaultValues: {
			times: useMemo(() => {
				return asNeededMedsTaken;
			}, [asNeededMedsTaken]),
		},
	});

	const { fields, append, remove } = useFieldArray({
		control,
		name: 'times',
	});

	const addNewTime = () => append({ scheduledMedTakenId: 0, date, time: '' });

	const handleChangeTime = (newValue: dayjs.Dayjs | null, data: IAsNeededMedsTaken) => {
		if (newValue) {
			onChangeTime({ ...data, time: newValue.format('hh:mm A') });
		}
	};

	useEffect(() => {
		reset({ times: asNeededMedsTaken });
	}, [asNeededMedsTaken, reset]);

	return (
		<Card key={name} sx={{ flexGrow: 1, backgroundColor: 'background.default' }}>
			<CardHeader title={name} titleTypographyProps={{ variant: 'h6' }} />
			<CardContent>
				<Stack direction="column" useFlexGap spacing={2}>
					{fields.map((field, index) => (
						<Stack key={field.id} direction="row" useFlexGap spacing={2}>
							<Controller
								name={`times.${index}.scheduledMedTakenId`}
								control={control}
								render={({ field: { value, ...restField } }) => <input type="hidden" value={value} {...restField} />}
							/>
							<Controller
								name={`times.${index}.date`}
								control={control}
								render={({ field: { value, ...restField } }) => <input type="hidden" value={value} {...restField} />}
							/>
							<Controller
								name={`times.${index}.time`}
								control={control}
								render={({ field: { value, onChange, ...restField } }) => (
									<TimePicker
										slotProps={{
											textField: {
												size: 'small',
											},
											field: {
												readOnly: true,
											},
											desktopPaper: {
												elevation: 3,
												sx: {
													background: (theme) => theme.palette.background.default,
													border: '2px solid',
													borderColor: 'primary.main',
												},
											},
										}}
										value={dayjs(value.toUpperCase(), 'h:mm A')}
										onChange={(newValue) => {
											onChange(newValue ? newValue.format('hh:mm A') : '');
										}}
										onAccept={(newValue) => {
											handleChangeTime(newValue, getValues(`times.${index}`));
										}}
										{...restField}
									/>
								)}
							/>
							<Button
								variant="text"
								size="small"
								onClick={() => {
									onDeleteTime(getValues(`times.${index}`));
									remove(index);
								}}
							>
								Remove
							</Button>
						</Stack>
					))}
				</Stack>
			</CardContent>
			<CardActions>
				<Button variant="contained" color="primary" size="small" onClick={() => addNewTime()}>
					Log As Needed Medication
				</Button>
			</CardActions>
		</Card>
	);
}

export default AsNeededMedByDayListItem;
