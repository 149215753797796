import { queryOptions } from '@tanstack/react-query';
import { getAdminsListN } from 'core/api/admin/admins.api';
import { IAdminsListRequest } from 'core/api/admin/admins.models';

export const adminQueries = {
	adminsList: ({ page, ...restRequest }: IAdminsListRequest) =>
		queryOptions({
			queryKey: ['admin', 'admins', 'list', restRequest, page],
			queryFn: () => getAdminsListN({ page, ...restRequest }),
		}),
};
