import React, { useState, useRef } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { joiResolver } from '@hookform/resolvers/joi';
import { TextField, Box, Button, Typography, Stack } from '@mui/material';
import Joi from 'joi';
import DateOfBirthField from 'components/common/inputs/DateOfBirthField';
import { ICheckStatusResponse, IVerifyIdentitySearchQuery } from 'core/api/registration/registration.models';
import ReCAPTCHA from 'react-google-recaptcha';
import { useRegisterMutation } from 'hooks/auth/useRegisterMutation';
import Suspense from 'components/Suspense';
import setFormErrors from 'utils/setFormErrors';
import FormErrorMessages from 'components/common/inputs/FormErrorMessages';

const schema = Joi.object({
	dateOfBirth: Joi.string().required().messages({
		'*': 'Please enter a valid date.',
	}),
	lastFourSsn: Joi.string().min(4).max(4).required().messages({
		'*': 'Please enter a valid social security number.',
	}),
	zipCode: Joi.string().min(5).max(5).required().messages({
		'*': 'Please enter a valid ZIP code.',
	}),
});

export interface RegisterVerifyYourInfoProps extends Partial<ICheckStatusResponse> {
	onGetHash: (hash: string) => void;
}

export function RegisterVerifyYourInfo({ onGetHash }: RegisterVerifyYourInfoProps) {
	const { verifyIdentity } = useRegisterMutation();
	const { mutate: verifyIdentityMutate, isPending } = verifyIdentity;
	const [recaptchaLoading, setRecaptchaLoading] = useState<boolean>(true);

	const recaptchaRef = useRef<ReCAPTCHA>(null);

	const {
		control,
		handleSubmit,
		formState: { errors },
		setError,
		clearErrors,
	} = useForm<IVerifyIdentitySearchQuery>({
		mode: 'onChange',
		resolver: joiResolver(schema),
	});

	const handleVerifyYourInfo = async (data: IVerifyIdentitySearchQuery) => {
		clearErrors();

		if (recaptchaRef?.current) {
			const recaptchaToken = (await recaptchaRef.current.executeAsync()) || '';

			verifyIdentityMutate(
				{
					...data,
					recaptchaToken,
				},
				{
					onSuccess(identityData) {
						recaptchaRef?.current?.reset();
						if (identityData?.error) {
							setError('root.serverError', {
								type: 'server',
								message: identityData.error,
							});
						} else {
							onGetHash(identityData?.hash || '');
						}
					},
					onError: (error) => {
						recaptchaRef?.current?.reset();

						setFormErrors(error, setError);
					},
				}
			);
		} else {
			setError('root.recaptchaError', {
				type: 'recaptcha',
				message: 'ReCAPTCHA was not initialized properly. Please refresh the page and try again.',
			});
		}
	};

	return (
		<>
			<Suspense isLoading={recaptchaLoading}>
				<Typography component="span" variant="h4" fontWeight="bold" color="text.secondary">
					Verify Your Information
				</Typography>
				<Stack gap="24px" component="form" onSubmit={handleSubmit(handleVerifyYourInfo)} sx={{ mt: 2 }}>
					<Controller
						name="dateOfBirth"
						control={control}
						render={({ field: { value, ...restField }, fieldState: { error } }) => (
							<DateOfBirthField
								{...restField}
								value={value || ''}
								error={!!error}
								helperText={error?.message}
								label="What is your date of birth?"
							/>
						)}
					/>

					<Controller
						name="lastFourSsn"
						control={control}
						render={({ field: { value, ...restField }, fieldState: { error } }) => (
							<TextField
								{...restField}
								value={value || ''}
								error={!!error}
								helperText={error?.message}
								label="What are the last four digits of your Social Security Number?"
								variant="standard"
								size="small"
								placeholder="####"
								inputProps={{
									maxLength: 4,
								}}
								sx={{
									'& .MuiInputBase-root': {
										width: '200px',
									},
								}}
							/>
						)}
					/>

					<Controller
						name="zipCode"
						control={control}
						render={({ field: { value, ...restField }, fieldState: { error } }) => (
							<TextField
								{...restField}
								value={value || ''}
								label="What is the ZIP code of the address where your medications are delivered?"
								error={!!error}
								helperText={error?.message}
								variant="standard"
								size="small"
								placeholder="#####"
								inputProps={{
									maxLength: 5,
								}}
								sx={{
									'& .MuiInputBase-root': {
										width: '200px',
									},
								}}
							/>
						)}
					/>

					<FormErrorMessages errors={errors} name="root" />

					<Box sx={{ width: { xs: '100%', sm: '50%' } }}>
						<Button disabled={isPending} variant="contained" color="primary" fullWidth type="submit">
							{isPending ? 'Verifying...' : 'Verify Your Identity'}
						</Button>
					</Box>
				</Stack>
			</Suspense>

			<ReCAPTCHA
				ref={recaptchaRef}
				sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY || ''}
				size="invisible"
				asyncScriptOnLoad={() => setRecaptchaLoading(false)}
			/>
		</>
	);
}

export default RegisterVerifyYourInfo;
