import React from 'react';

import { Link as RouterLink, UIMatch, useLocation, useMatches, useParams } from 'react-router-dom';
import { Button, Box, Menu, MenuItem, Toolbar, Link, AppBar, Divider, Badge, Stack } from '@mui/material';
import { useTheme, styled } from '@mui/material/styles';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import useMediaQuery from '@mui/material/useMediaQuery';
import routes from 'routes';
import AnewHealthExactCareLightLogo from 'components/logo/AnewHealthExactCareLightLogo';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import HideOnScroll from 'components/HideOnScroll';
import { SUPPORT_URLS } from 'constants/urls';
import useShowSidebarNavigation from 'hooks/useShowSidebarNavigation';
import { camelize, isActiveRoute } from 'utils';

interface MainMenuItem {
	label: string;
	to: string;
	icon?: React.ReactNode;
	notifications?: number | string;
	activeRoutes?: string[];
	target?: string;
}

interface HeaderProps {
	mainMenu: MainMenuItem[];
	onLogout: () => void;
	accountBalance: string | null;
	accountName: string | null;
}

const MobileTopAppBar = styled(AppBar)(({ theme }) => ({
	boxShadow: 'none',
	backgroundColor: theme.palette.background.secondary,

	'& .MuiToolbar-root': {
		height: '110px',
		padding: '12px',
		alignItems: 'flex-end',
		justifyContent: 'space-between',
	},
}));

const MobileBottomAppBar = styled(AppBar)(({ theme }) => ({
	top: 'auto',
	bottom: 0,
	background: theme.palette.background.default,
	boxShadow: '0px -4px 12px 0px rgba(117, 117, 117, 0.3)',
	'& .MuiToolbar-root': {
		color: theme.palette.text.secondary,
		textAlign: 'center',
		fontSize: '0.75rem',
		fontWeight: '700',
		lineHeight: '1rem',
		letterSpacing: '0.031rem',
		display: 'flex',
		flexDirection: 'row',
		flexWrap: 'nowrap',
		justifyContent: 'space-evenly',
		alignItems: 'center',
		padding: '0px 8px 0px 8px',
		gap: '4px',
	},
	'& .MuiLink-root': {
		textDecoration: 'none',
		display: 'flex',
		flexDirection: 'column',
		flexWrap: 'nowrap',
		alignItems: 'center',
		color: theme.palette.text.primary,
		padding: '12px 0px 24px 0px',
		gap: '4px',
		'& .MuiBadge-anchorOriginTopRight': {
			transform: 'scale(1) translate(5%, -5%)',
		},
		'& .iconButton': {
			display: 'flex',
			width: '100%',
			alignItems: 'center',
			justifyContent: 'center',
			borderRadius: '16px',
			padding: '6px 20px 6px 20px',
		},
		'&.active': {
			color: theme.palette.text.secondary,
			'& .iconButton': {
				background: theme.palette.grey[100],
				boxShadow: '0px 0px 4px 0px rgba(34, 34, 34, 0.25) inset',
			},
		},
	},
}));

const DesktopAppBar = styled(Box)(({ theme }) => ({
	backgroundColor: theme.palette.background.default,
	borderRadius: '0px 0px 20px 20px',
	'& .MuiToolbar-root': {
		gap: '24px',
		padding: '32px 24px 24px 24px',
	},
	'& .MainMenu': {
		background: theme.palette.grey[100],
		boxShadow: '0px 0px 8px 0px rgba(34, 34, 34, 0.15) inset',
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		borderRadius: '50px',
		padding: '4px',
		gap: '10px',
		'& .MuiBadge-anchorOriginTopRight': {
			transform: 'scale(1) translate(5%, -5%)',
			lineHeight: '1rem',
			fontSize: '0.75rem',
			fontWeight: 700,
		},
		'& .MuiLink-root': {
			padding: '6px 22px 6px 22px',
			gap: '10px',
			borderRadius: '100px',
			color: theme.palette.grey[900],
			fontWeight: 700,
			fontSize: theme.typography.h1.fontSize,
			lineHeight: theme.typography.h1.lineHeight,
			display: 'inline-flex',
			justifyContent: 'center',
			alignItems: 'center',
			textAlign: 'center',
			textDecoration: 'none',
			border: '2px solid transparent',
			'&.active': {
				borderColor: theme.palette.grey[900],
			},
			[theme.breakpoints.down('lg')]: {
				fontSize: '1rem',
				lineHeight: '1.5rem',
			},
		},
	},
}));

const AccountMenu = styled(Menu)(({ theme }) => ({
	'& .MuiPaper-root': {
		borderRadius: '10px',
		backgroundColor: 'rgba(255, 255, 255, 1)',
		boxShadow: '0px 0px 8px 0px rgba(97, 0, 138, 0.10), 0px 0px 1px 0px rgba(97, 0, 138, 0.05)',
	},
	'& .MuiMenuItem-root': {
		fontWeight: 700,
		justifyContent: 'flex-end',
		fontSize: '1rem',
		lineHeight: '1.5rem',
	},
	'& .MuiMenuItem-root.Eca-account-balance': {
		opacity: 100,
		color: theme.palette.primary.main,
	},
}));

function MobileHeader({ mainMenu }: Omit<HeaderProps, 'onLogout'>) {
	const matches = useMatches() as UIMatch<
		unknown,
		{
			back: ({ params }: any) => {
				name: string;
				to: string;
				state: { showSidebarNavigation?: boolean };
			};
		}
	>[];
	const location = useLocation();
	const queryParams = useParams();
	const showSidebarNavigation = useShowSidebarNavigation();

	const backButton = matches.find((match) => Boolean(match.handle?.back))?.handle?.back({ params: queryParams });
	const showLogo = location.pathname === routes.home;

	return (
		<>
			<HideOnScroll>
				<MobileTopAppBar sx={{ height: showLogo ? '110px' : '76px' }}>
					<Toolbar>
						<Stack gap={2}>
							{!showSidebarNavigation && backButton && (
								<Link
									component={RouterLink}
									to={backButton.to}
									state={backButton.state}
									fontWeight="bold"
									underline="none"
									variant="body1"
									color="secondary.main"
									sx={{
										display: 'flex',
									}}
								>
									<ArrowBackIosIcon sx={{ fontSize: '0.875rem' }} />
									{backButton.name}
								</Link>
							)}
							{showLogo && (
								<Link to={routes.home} component={RouterLink}>
									<AnewHealthExactCareLightLogo sx={{ width: '222px', height: 'auto', display: 'block' }} />
								</Link>
							)}
						</Stack>
						<Link href={SUPPORT_URLS.HELP_CENTER} target="_blank" color="text.primary" fontSize="1.5rem">
							<HelpOutlineIcon />
						</Link>
					</Toolbar>
				</MobileTopAppBar>
			</HideOnScroll>
			<Toolbar sx={{ minHeight: showLogo ? '110px' : '76px' }} />
			<MobileBottomAppBar position="fixed" data-testid="mobile-app-bar" className="EcaBox-mainHeader">
				<Toolbar>
					{mainMenu
						.filter((item) => item.icon)
						.map(({ label, to, icon, notifications, activeRoutes, target }) => (
							<Link
								component={RouterLink}
								to={to}
								className={`EcaLink-${camelize(label)} ${isActiveRoute(location.pathname, activeRoutes) ? 'active' : ''}`}
								target={target}
								key={label}
							>
								{notifications && notifications !== 0 ? (
									<Badge badgeContent={notifications} color="error" max={9}>
										<Box className="iconButton">{icon}</Box>
									</Badge>
								) : (
									<Box className="iconButton">{icon}</Box>
								)}
								{label}
							</Link>
						))}
					<Link
						component={RouterLink}
						to={routes.account.personalInformation}
						className={
							isActiveRoute(location.pathname, [
								routes.account.personalInformation,
								routes.account.paymentsAndBilling,
								routes.account.notificationsPreferences,
							])
								? 'active'
								: ''
						}
					>
						<Box className="iconButton">
							<AccountCircleOutlinedIcon />
						</Box>
						Account
					</Link>
				</Toolbar>
			</MobileBottomAppBar>
		</>
	);
}

function DesktopHeader({ mainMenu, onLogout, accountBalance, accountName }: HeaderProps) {
	const location = useLocation();
	const [anchorAccountMenu, setAnchorAccountMenu] = React.useState<Element | null>(null);

	const handleAccountMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
		if (event?.currentTarget) {
			setAnchorAccountMenu(event.currentTarget);
		}
	};

	const handleAccountMenuClose = () => {
		setAnchorAccountMenu(null);
	};

	return (
		<DesktopAppBar data-testid="desktop-app-bar" className="EcaBox-mainHeader">
			<Toolbar>
				<Box sx={{ display: 'flex' }}>
					<Link to={routes.home} component={RouterLink}>
						<AnewHealthExactCareLightLogo sx={{ width: '250px', height: 'auto', display: 'block' }} />
					</Link>
				</Box>
				<Box sx={{ flexGrow: 1 }} />
				<Box className="MainMenu">
					{mainMenu.map(({ label, to, notifications, activeRoutes, target }) => (
						<React.Fragment key={label}>
							{notifications && notifications !== 0 ? (
								<Badge badgeContent={notifications} color="error" max={9}>
									<Link
										component={RouterLink}
										to={to}
										className={`EcaLink-${camelize(label)} ${isActiveRoute(location.pathname, activeRoutes) ? 'active' : ''}`}
										target={target}
									>
										{label}
									</Link>
								</Badge>
							) : (
								<Link
									component={RouterLink}
									to={to}
									className={`EcaLink-${camelize(label)} ${isActiveRoute(location.pathname, activeRoutes) ? 'active' : ''}`}
									target={target}
								>
									{label}
								</Link>
							)}
						</React.Fragment>
					))}
				</Box>
				<Button
					id="account-button"
					variant="text"
					aria-controls={anchorAccountMenu ? 'account-menu' : undefined}
					aria-expanded={anchorAccountMenu ? 'true' : undefined}
					aria-haspopup="true"
					onClick={handleAccountMenu}
					endIcon={<KeyboardArrowDownIcon />}
					sx={{
						'&.MuiButton-text': {
							fontSize: '16px',
							textDecoration: 'none',
							textTransform: 'capitalize',
							color: 'text.secondary',
							':hover': {
								textDecoration: 'none',
								opacity: 0.8,
							},
						},
					}}
				>
					<AccountCircleOutlinedIcon sx={{ marginRight: '5px', color: 'grey.500' }} /> {accountName || 'N/A'}
				</Button>
				<AccountMenu
					anchorEl={anchorAccountMenu}
					open={Boolean(anchorAccountMenu)}
					onClose={handleAccountMenuClose}
					id="account-menu"
					aria-labelledby="account-button"
				>
					<MenuItem
						component={RouterLink}
						to={routes.account.personalInformation}
						selected={isActiveRoute(location.pathname, [routes.account.personalInformation])}
					>
						Personal Info
					</MenuItem>
					<MenuItem
						component={RouterLink}
						to={routes.account.notificationsPreferences}
						selected={isActiveRoute(location.pathname, [routes.account.notificationsPreferences])}
					>
						Notifications
					</MenuItem>
					{accountBalance && (
						<MenuItem
							className="Eca-account-balance"
							component={RouterLink}
							to={routes.account.paymentsAndBilling}
							selected={isActiveRoute(location.pathname, [routes.account.paymentsAndBilling])}
						>
							Balance: {accountBalance}
						</MenuItem>
					)}
					<Divider />
					<MenuItem onClick={onLogout}>Sign Out</MenuItem>
				</AccountMenu>
			</Toolbar>
		</DesktopAppBar>
	);
}

export default function Header(props: HeaderProps) {
	const theme = useTheme();
	const upDesktopScreen = useMediaQuery(theme.breakpoints.up('sm'));

	return upDesktopScreen ? <DesktopHeader {...props} /> : <MobileHeader {...props} />;
}
