import React from 'react';
import { Stack, Alert, Button } from '@mui/material';
import Joi from 'joi';
import { Controller, useForm } from 'react-hook-form';
import { joiResolver } from '@hookform/resolvers/joi';
import PasswordField from 'components/common/inputs/passwordField/PasswordField';
import { buildPasswordSchema } from 'utils/buildPasswordSchema';
import { IAppError } from 'core/errors/AppError';
import { IChangePasswordPatientRequest } from 'core/api/admin/patients.models';

const schema = Joi.object({
	newPassword: buildPasswordSchema(),
	repeatNewPassword: Joi.string().required().valid(Joi.ref('newPassword')).messages({
		'string.empty': 'Repeat new password cannot be empty',
		'any.required': 'Repeat new password is required',
		'any.only': 'New password and repeat new password must match',
	}),
});

interface PatientChangePasswordForm {
	newPassword: string;
	repeatNewPassword: string;
}

interface PatientChangePasswordProps {
	userId: string;
	onSubmit: (data: IChangePasswordPatientRequest) => void;
	isLoading: boolean;
	error?: IAppError | null;
}

export function PatientChangePassword({
	onSubmit,
	userId,
	isLoading,
	error: changePasswordError,
}: PatientChangePasswordProps) {
	const {
		control,
		handleSubmit,
		formState: { isValid },
	} = useForm<PatientChangePasswordForm>({ mode: 'onChange', resolver: joiResolver(schema) });

	const handleSubmitChangePassword = (data: PatientChangePasswordForm) => {
		onSubmit({ userId, newPassword: data.newPassword });
	};

	return (
		<Stack gap={2} mt={2} component="form" onSubmit={handleSubmit(handleSubmitChangePassword)}>
			{changePasswordError?.message && (
				<Alert severity="error" sx={{ mb: 2 }}>
					{changePasswordError.message}
				</Alert>
			)}
			<Controller
				name="newPassword"
				control={control}
				render={({ field, fieldState: { error } }) => (
					<PasswordField
						{...field}
						value={field.value || ''}
						label="New password"
						variant="outlined"
						size="small"
						fullWidth
						helperText={error?.message}
						error={!!error}
					/>
				)}
			/>
			<Controller
				name="repeatNewPassword"
				control={control}
				render={({ field, fieldState: { error } }) => (
					<PasswordField
						{...field}
						value={field.value || ''}
						label="Repeat new password"
						variant="outlined"
						size="small"
						fullWidth
						helperText={error?.message}
						error={!!error}
						disableTooltip
					/>
				)}
			/>
			<Button disabled={!isValid && isLoading} variant="outlined" color="primary" fullWidth type="submit">
				{isLoading ? 'Loading...' : 'Save'}
			</Button>
		</Stack>
	);
}

export default PatientChangePassword;
