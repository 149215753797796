import React from 'react';
import { TextField, Button, Box, Typography, Stack } from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import { joiResolver } from '@hookform/resolvers/joi';
import Joi from 'joi';
import { PhoneNumberMaskInput } from 'components/common/inputs/textField/TextFieldMask';
import { ICheckStatusResponse, IRegisterPhoneNumberRequestParams } from 'core/api/registration/registration.models';
import { useRegisterMutation } from 'hooks/auth/useRegisterMutation';
import setFormErrors from 'utils/setFormErrors';
import FormErrorMessages from 'components/common/inputs/FormErrorMessages';

const schema = Joi.object({
	phoneNumber: Joi.string().required().messages({
		'*': 'Please enter a valid mobile phone number.',
	}),
});

export interface RegisterWithPhoneNumberProps extends Partial<ICheckStatusResponse> {
	hash: string;
	onSuccess: () => void;
}

export function RegisterWithPhoneNumber({ hash, onSuccess }: RegisterWithPhoneNumberProps) {
	const { registerPhoneNumber } = useRegisterMutation();
	const { mutate: phoneNumberMutate, isPending } = registerPhoneNumber;

	const {
		control,
		handleSubmit,
		formState: { errors: phoneNumberErrors },
		setError,
	} = useForm<IRegisterPhoneNumberRequestParams>({ mode: 'onChange', resolver: joiResolver(schema) });

	const handleVerifyWithPhoneNumber = handleSubmit(({ phoneNumber }) =>
		phoneNumberMutate({ phoneNumber, hash }, { onSuccess, onError: (error) => setFormErrors(error, setError) })
	);

	const handleVerifyWithEmail = () => phoneNumberMutate({ phoneNumber: null, hash }, { onSuccess });

	return (
		<>
			<Typography component="span" variant="h4" fontWeight="bold" color="text.secondary">
				Confirm Your Mobile Phone Number
			</Typography>
			<Stack gap="24px" component="form" onSubmit={handleVerifyWithPhoneNumber}>
				<>
					<Typography fontWeight={700} color="text.secondary">
						Enter your mobile phone number to activate your account.
					</Typography>

					<Typography component="span" variant="small">
						This phone number will be used for service notifications and text messages from ExactCare. We only message
						you about your service.
					</Typography>
				</>
				<Controller
					name="phoneNumber"
					control={control}
					render={({ field, fieldState: { error } }) => (
						<TextField
							{...field}
							error={!!error}
							helperText={error?.message}
							label="Enter Your Phone Number"
							variant="standard"
							size="small"
							sx={{
								'& .MuiInputBase-root': {
									width: { xs: '100%', md: '56%' },
								},
							}}
							InputProps={{ inputComponent: PhoneNumberMaskInput as any }}
							fullWidth
						/>
					)}
				/>

				<FormErrorMessages errors={phoneNumberErrors} name="root" />

				<Box sx={{ width: { xs: '100%', sm: '50%' } }}>
					<Button disabled={isPending} variant="contained" color="primary" fullWidth type="submit">
						{isPending ? 'Loading...' : 'Send Your Verification Code'}
					</Button>
				</Box>

				<Stack direction="column" sx={{ width: { xs: '100%', sm: '50%' } }}>
					<Typography variant="input2" fontWeight={700}>
						Prefer not to share your phone number?
					</Typography>
					<Button
						onClick={handleVerifyWithEmail}
						variant="text"
						sx={{ fontWeight: '400', fontSize: '14px', color: 'text.secondary' }}
					>
						Verify with your email
					</Button>
				</Stack>
			</Stack>
		</>
	);
}

export default RegisterWithPhoneNumber;
