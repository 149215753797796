import React from 'react';
import { Button, Typography, TextField, Stack } from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import { joiResolver } from '@hookform/resolvers/joi';
import Joi from 'joi';
import { PhoneNumberMaskInput } from 'components/common/inputs/textField/TextFieldMask';
import FormatPhoneNumber from 'utils/formatPhoneNumber';
import { ISendChangePhoneRequest } from 'core/api/userAccount/userAccount.models';

const schema = Joi.object({
	phoneNumber: Joi.string().required().messages({
		'*': 'Please enter a valid mobile phone number.',
	}),
});

interface INewMobileNumberProps {
	currentPhoneNumber: string | null;
	createChangePhoneRequestIsLoading: boolean;
	onNewMobileNumberAction: (data: ISendChangePhoneRequest) => void;
}

function NewMobileNumber({
	currentPhoneNumber,
	createChangePhoneRequestIsLoading,
	onNewMobileNumberAction,
}: INewMobileNumberProps) {
	const {
		control,
		handleSubmit,
		formState: { isValid },
	} = useForm<ISendChangePhoneRequest>({ mode: 'onChange', resolver: joiResolver(schema) });

	const formattedCurrentPhoneNumber = FormatPhoneNumber(currentPhoneNumber || '');

	return (
		<Stack gap="12px" component="form" onSubmit={handleSubmit(onNewMobileNumberAction)}>
			<Typography component="p" color="text.secondary">
				This phone number must be able to send and receive text messages. If you want to add a landline phone, please
				call us at 1-877-355-7225.
			</Typography>
			{currentPhoneNumber && (
				<Typography component="p" color="text.secondary">
					<b>Your current mobile number is:</b> {formattedCurrentPhoneNumber}
				</Typography>
			)}
			<Controller
				name="phoneNumber"
				control={control}
				render={({ field, fieldState: { error } }) => (
					<TextField
						{...field}
						label="Enter New Mobile Number"
						error={!!error}
						helperText={error?.message}
						variant="standard"
						size="small"
						// eslint-disable-next-line @typescript-eslint/no-explicit-any
						InputProps={{ inputComponent: PhoneNumberMaskInput as any }}
						fullWidth
					/>
				)}
			/>

			<Button
				disabled={!isValid && createChangePhoneRequestIsLoading}
				variant="contained"
				color="primary"
				fullWidth
				type="submit"
				sx={{ mt: 2 }}
			>
				{createChangePhoneRequestIsLoading ? 'Loading...' : 'Continue'}
			</Button>
		</Stack>
	);
}

export default NewMobileNumber;
