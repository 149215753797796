import React, { useCallback } from 'react';
import { Button, Typography, Box, ListItem, ListItemText, List } from '@mui/material';
import { styled } from '@mui/material/styles';
import ErrorRoundedIcon from '@mui/icons-material/ErrorRounded';
import { MedsItem, MedsItemContent, MedsItemImage, MedsList } from 'components/medsList';
import ContentCard from 'components/card/ContentCard';
import {
	IMedication,
	IMedsGrouped,
	MedicationAction,
	MedicationRefillAlert,
} from 'core/api/medications/medications.models';
import ImageWithPreview from 'components/imageWithPreview/imageWithPreview';
import eventService from 'services/eventService';

const RefillAlert = styled(Box)(({ theme }) => ({
	display: 'flex',
	padding: '24px',
	flexDirection: 'column',
	alignItems: 'flex-start',
	gap: '24px',
	alignSelf: 'stretch',
	borderRadius: '10px',
	background: theme.palette.background.secondary,

	'& .MuiListItemText-primary': {
		color: theme.palette.text.secondary,
	},
}));

const RefillAlertHead = styled(Box)(({ theme }) => ({
	display: 'flex',
	width: '100%',
	justifyContent: 'space-between',
	flexDirection: 'row',
	alignItems: 'center',

	[theme.breakpoints.down('sm')]: {
		flexDirection: 'column',
		gap: '16px',
		alignItems: 'stretch',
	},
}));

const RefillAlertHeadIcon = styled(Box)(() => ({
	display: 'flex',
	padding: '0px 8px',
	flexDirection: 'column',
	justifyContent: 'center',
	alignItems: 'center',
	gap: 10,
	'&  .MuiSvgIcon-root': {
		width: 36,
		height: 36,
	},
}));

const RefillAlertHeadTitle = styled(Box)(({ theme }) => ({
	display: 'flex',
	alignItems: 'flex-start',
	gap: 12,
	flex: '1 0 0',
	'& h3': {
		fontWeight: 700,
		color: theme.palette.text.secondary,
	},
	'& span': {
		fontWeight: 600,
		color: theme.palette.text.primary,
	},
}));

interface IRefillAlertsProps {
	medsGroupedByAlerts: IMedsGrouped | null;
	totalAlerts: number | null;
	onOpenRefillRequestForm: () => void;
}

const refillAlertTitleMap: { [key: string]: string } = {
	[MedicationRefillAlert.NO_REFILLS]: 'No refills. Please contact your prescriber.',
	[MedicationRefillAlert.REFILL_DENIED]: 'Refill denied. Please contact your prescriber.',
};

const refillAlertSubTitleMap: { [key: string]: string } = {
	[MedicationRefillAlert.NO_REFILLS]: 'Your prescriber has not responded to our requests.',
	[MedicationRefillAlert.REFILL_DENIED]: 'Your prescriber will not approve this refill without speaking to you.',
};

export function RefillAlerts({ medsGroupedByAlerts, totalAlerts, onOpenRefillRequestForm }: IRefillAlertsProps) {
	const showRefillRequestForm = useCallback(
		(medications: Partial<IMedication>[]) =>
			medications.some(
				({ actions, refillAlert }) =>
					actions?.includes(MedicationAction.GET_PRESCRIBER_REFILL_REQUEST_FORM) &&
					refillAlert === MedicationRefillAlert.NO_REFILLS
			),
		[]
	);

	return (
		<ContentCard title="Refill Alerts">
			{!totalAlerts && (
				<>
					<Typography component="p" variant="body1" color="text.secondary" fontWeight={700}>
						You have no alerts at this time.
					</Typography>
					<Typography component="p" variant="body1" color="text.secondary">
						We will send you refill alerts when you need to contact your prescriber about medication because we were
						unable to obtain a refill. You may need to contact them if we can’t reach them, or if they need to speak to
						you first. Call us with questions at 1-877-355-7335.
					</Typography>
				</>
			)}
			{!!totalAlerts && (
				<Typography variant="body1" color="text.secondary">
					ExactCare works with your healthcare team to get your refills. Sometimes, they may not respond to our
					requests. That’s when we need your help.
				</Typography>
			)}
			{!!totalAlerts &&
				Object.entries(medsGroupedByAlerts || {}).map(([title, medications]) => (
					<RefillAlert key={title}>
						<RefillAlertHead>
							<RefillAlertHeadTitle>
								<RefillAlertHeadIcon>
									<ErrorRoundedIcon color="error" />
								</RefillAlertHeadIcon>
								<Box>
									<Typography component="h3" variant="h2">
										{refillAlertTitleMap?.[title] || ''}
									</Typography>
									<Typography component="span">{refillAlertSubTitleMap?.[title] || ''}</Typography>
								</Box>
							</RefillAlertHeadTitle>
							{showRefillRequestForm(medications) && (
								<Button
									onClick={() => {
										onOpenRefillRequestForm();
										eventService.emitEvent({ category: 'button', action: 'openRefillRequestForm' });
									}}
									variant="contained"
									color="primary"
								>
									Download Refill Request Form
								</Button>
							)}
						</RefillAlertHead>
						<MedsList>
							{medications.map(({ sfid, name, imageUrl, prescriberName, prescriberPhone, rxNumber }) => (
								<MedsItem key={`${title} ${sfid}`}>
									<MedsItemImage>
										{imageUrl && (
											<ImageWithPreview
												source={imageUrl}
												title={name || ''}
												onClick={() =>
													eventService.emitEvent({ category: 'imageWithPreview', action: 'refillAlertZoomOnPicture' })
												}
											>
												<Box component="img" src={imageUrl} alt={name || ''} />
											</ImageWithPreview>
										)}
									</MedsItemImage>
									<MedsItemContent>
										<Typography component="h4" variant="h2" color="text.secondary" fontWeight={700}>
											{name}
										</Typography>
										<List>
											<ListItem>
												<ListItemText variant="body1" primary="Rx Number:" secondary={rxNumber} />
											</ListItem>
											<ListItem>
												<ListItemText variant="body1" primary="Prescriber:" secondary={prescriberName} />
											</ListItem>
											<ListItem>
												<ListItemText variant="body1" primary="Prescriber Phone:" secondary={prescriberPhone} />
											</ListItem>
											<ListItem>
												<ListItemText
													variant="body1"
													secondary="If this is not your prescriber, please contact ExactCare at (877) 355-7225."
												/>
											</ListItem>
										</List>
									</MedsItemContent>
								</MedsItem>
							))}
						</MedsList>
					</RefillAlert>
				))}
		</ContentCard>
	);
}

export default RefillAlerts;
