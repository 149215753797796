import React from 'react';
import { Stack, TextField, Button, Typography, Alert } from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import { joiResolver } from '@hookform/resolvers/joi';
import Joi from 'joi';
import PasswordField from 'components/common/inputs/passwordField/PasswordField';
import { useUserContext } from 'contexts/UserProvider';
import { useAccountEmailMutation } from 'hooks/userAccount/useAccountEmailMutation';

const schema = Joi.object({
	newEmail: Joi.string().required().messages({
		'*': 'Please enter a valid email address.',
	}),
	repeatNewEmail: Joi.string().required().valid(Joi.ref('newEmail')).messages({
		'any.only': 'The email addresses do not match. Please check and try again.',
		'string.empty': 'Repeat email cannot be empty',
		'any.required': 'Repeat email is required',
	}),
	password: Joi.string().required().messages({
		'string.empty': 'Current password cannot be empty',
		'any.required': 'Current password is required',
	}),
});

interface IChangeEmailForm {
	newEmail: string;
	repeatNewEmail: string;
	password: string;
}

export function ChangeEmail() {
	const { email: currentEmail } = useUserContext();
	const { changeEmailMutation } = useAccountEmailMutation();
	const {
		control,
		handleSubmit,
		getValues,
		formState: { isValid },
	} = useForm<IChangeEmailForm>({ mode: 'onChange', resolver: joiResolver(schema) });

	const handleChangeEmail = (data: IChangeEmailForm) => changeEmailMutation.mutate(data);

	return (
		<>
			{changeEmailMutation.isSuccess && (
				<Stack gap={2}>
					<Typography component="p" fontWeight={700} color="text.secondary">
						{getValues('newEmail')} <br />
						<Typography component="span" color="error" fontWeight={700}>
							Unverified
						</Typography>
					</Typography>
					<Typography component="p" color={(theme) => theme.palette.success.main}>
						A confirmation email was sent to the requested email address. Please follow the link in the email to
						complete the request.
					</Typography>
				</Stack>
			)}
			{!changeEmailMutation.isSuccess && (
				<Stack gap={2} component="form" onSubmit={handleSubmit(handleChangeEmail)}>
					<Typography component="p" color="text.secondary">
						<b>Your current email is:</b>
						<br />
						{currentEmail}
					</Typography>

					{changeEmailMutation.error?.message && (
						<Alert severity="error" sx={{ mb: 2 }}>
							{changeEmailMutation.error.message}
						</Alert>
					)}

					<Controller
						name="newEmail"
						control={control}
						render={({ field, fieldState: { error } }) => (
							<TextField
								{...field}
								value={field.value || ''}
								error={!!error}
								helperText={error?.message}
								variant="standard"
								label="Enter New Email"
								size="small"
								type="email"
								autoComplete="off"
								fullWidth
							/>
						)}
					/>

					<Controller
						name="repeatNewEmail"
						control={control}
						render={({ field, fieldState: { error } }) => (
							<TextField
								{...field}
								value={field.value || ''}
								helperText={error?.message}
								error={!!error}
								variant="standard"
								label="Repeat New Email"
								size="small"
								type="email"
								autoComplete="off"
								fullWidth
							/>
						)}
					/>

					<Controller
						name="password"
						control={control}
						render={({ field, fieldState: { error } }) => (
							<PasswordField
								{...field}
								value={field.value || ''}
								helperText={error?.message}
								error={!!error}
								label="Current password"
								autoComplete="current-password"
								variant="standard"
								size="small"
								disableTooltip
								fullWidth
							/>
						)}
					/>

					<Button
						disabled={!isValid && changeEmailMutation.isPending}
						variant="contained"
						color="primary"
						fullWidth
						type="submit"
						sx={{ mt: 2 }}
					>
						{changeEmailMutation.isPending ? 'Loading...' : 'Save'}
					</Button>
				</Stack>
			)}
		</>
	);
}

export default ChangeEmail;
