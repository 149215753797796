import React from 'react';
import { Alert, Button, Link, Stack, Typography } from '@mui/material';
import ContentCard from 'components/card/ContentCard';
import { useUserContext } from 'contexts/UserProvider';
import useMakePaymentMutation from 'hooks/useMakePaymentMutation';
import { SUPPORT_URLS } from 'constants/urls';

function PaymentBillingPage() {
	const { balance, balanceUpdatedAt } = useUserContext();
	const { mutate: fetchPaymentUrl, isPending, isError } = useMakePaymentMutation();
	const handleClickMakeAPayment = () => {
		fetchPaymentUrl(undefined, {
			onSuccess: (paymentData) => {
				if (paymentData) {
					window.open(paymentData.url, '_blank');
				}
			},
		});
	};

	return (
		<ContentCard title="Payment and Billing">
			{isError && (
				<Alert severity="error" sx={{ margin: '0.25rem 0' }}>
					Something went wrong. Please try again later.
				</Alert>
			)}
			<Stack>
				<Typography variant="h6" fontWeight={700} color="text.secondary">
					Your Current Balance
				</Typography>
				<Typography variant="body1" fontWeight={700} color="text.secondary">
					Last Updated {balanceUpdatedAt}
				</Typography>
			</Stack>
			{balance && (
				<Typography component="p" variant="h6" color="text.secondary" fontWeight={700}>
					{balance}
				</Typography>
			)}
			<Stack alignItems="flex-start" gap="12px">
				<Button
					onClick={handleClickMakeAPayment}
					disabled={isPending}
					variant="contained"
					color="primary"
					type="submit"
				>
					{isPending ? 'Loading...' : 'Make a Payment'}
				</Button>
				<Typography variant="body1" fontStyle="italic" color="text.secondary">
					Come back here once your first statement is issued.
				</Typography>
				<Typography variant="body1" component="p" color="text.secondary">
					ExactCare does not charge for our pharmacy services or delivery. You are responsible for only your
					prescription copayments, as determined by your insurance, and any medications not covered by insurance that
					you have requested. Get more info about payments and billing{' '}
					<Link href={SUPPORT_URLS.PAYMENT} target="_blank">
						on our website.
					</Link>
				</Typography>
			</Stack>
		</ContentCard>
	);
}

export default PaymentBillingPage;
