export interface IApiError<Violations = Record<string, string>> {
	status: number;
	name: string;
	message: string;
	violations?: Violations;
}

export interface IViolation {
	propertyPath: string;
	message: string;
}

export class ApiError extends Error implements IApiError {
	public status;

	public message;

	public violations;

	constructor(status: number, message: string, violations?: IViolation[]) {
		super(message);
		this.name = 'ApiError';
		this.status = status;
		this.message = message;

		if (violations) {
			this.violations = violations.reduce(
				(acc, violation) => {
					const newAcc = { ...acc };
					if (!newAcc[violation.propertyPath]) {
						newAcc[violation.propertyPath] = violation.message;
					} else {
						newAcc[violation.propertyPath] = newAcc[violation.propertyPath].concat(violation.message);
					}

					return newAcc;
				},
				{} as IApiError['violations']
			);
		}

		Object.setPrototypeOf(this, ApiError.prototype);
	}
}
