import React, { useCallback, useMemo, useState, useRef } from 'react';
import { Box, Button } from '@mui/material';
import { GridColDef, GridRowParams } from '@mui/x-data-grid';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import { FiltersBar } from 'components/common/inputs/filters';
import DataGrid, { GridActionsCellItem } from 'components/dataGrid';
import { useUserContext } from 'contexts/UserProvider';
import { Paper, PaperTitle } from 'components/dashboardLayout';
import { CareTeamMemberDeleteParams, CareTeamMemberViewParams } from 'core/api/enterprise/supervisor.models';
import Dialog from 'components/dialog';
import { useDialog, useCareTeamMemberDeleteMutation, useCareTeamMemberListQuery } from 'hooks';
import ConfirmationDialog from 'components/confirmationDialog';
import { CareTeamMemberPatientsAccess, CareTeamMemberSave } from 'components/enterprise';
import { UserRole } from 'core/api/userAccount/userAccount.models';

export function CareTeamMemberList() {
	const [isOpenPatientsAccessDialog, openPatientsAccessDialog, closePatientsAccessDialog, patientsAccessData] =
		useDialog<Partial<CareTeamMemberViewParams>>();

	const [
		isOpenCareTeamMemberSaveDialog,
		openCareTeamMemberSaveDialog,
		closeCareTeamMemberSaveDialog,
		careTeamMemberSaveData,
	] = useDialog<Partial<CareTeamMemberViewParams>>();

	const [
		isOpenCareTeamMemberDeleteConfirmationDialog,
		openCareTeamMemberDeleteConfirmationDialog,
		closeCareTeamMemberDeleteConfirmationDialog,
		careTeamMemberDeleteData,
	] = useDialog<CareTeamMemberDeleteParams>();
	const { mutate: careTeamMemberDeleteMutate, isPending: isPendingDelete } = useCareTeamMemberDeleteMutation();

	const { roles } = useUserContext();
	const [paginationModel, setPaginationModel] = useState({
		page: 0,
		pageSize: 25,
	});
	const { data, isLoading } = useCareTeamMemberListQuery({
		page: paginationModel.page + 1,
		itemsPerPage: paginationModel.pageSize,
	});
	const { member: careTeamMembersData, totalItems: careTeamMembersTotal } = data ?? {};

	const rowCountRef = useRef(careTeamMembersTotal || 0);
	const rowCount = useMemo(() => {
		if (careTeamMembersTotal !== undefined) {
			rowCountRef.current = careTeamMembersTotal;
		}
		return rowCountRef.current;
	}, [careTeamMembersTotal]);

	const getActions = useCallback(
		({ row }: GridRowParams) => {
			const actions = [
				<GridActionsCellItem
					onClick={() => openPatientsAccessDialog({ id: row.id })}
					icon={<GroupAddIcon />}
					label="Assign Patients"
					title="Assign Patients"
					className="textPrimary"
					color="inherit"
				/>,
				<GridActionsCellItem
					onClick={() => openCareTeamMemberSaveDialog({ id: row.id })}
					icon={<EditIcon />}
					label="Edit"
					title="Edit"
					className="textPrimary"
					color="inherit"
				/>,
			];

			if (roles && roles.includes(UserRole.ENTERPRISE_SUPERVISOR)) {
				actions.push(
					<GridActionsCellItem
						onClick={() => openCareTeamMemberDeleteConfirmationDialog({ id: row.id })}
						icon={<DeleteIcon />}
						label="Delete"
						title="Delete"
						className="textPrimary"
						color="inherit"
					/>
				);
			}
			return actions;
		},
		[roles]
	);
	const columns = useMemo<GridColDef[]>(
		() => [
			{ field: 'id', headerName: 'ID', sortable: false, flex: 1 },
			{ field: 'name', headerName: 'Name', sortable: false, flex: 4 },
			{ field: 'patientsCount', headerName: 'Patients', sortable: false, flex: 1 },
			{ field: 'email', headerName: 'Email', sortable: false, flex: 4 },
			{ field: 'phoneNumber', headerName: 'Phone', sortable: false, flex: 2 },
			{ field: 'lastLogin', headerName: 'Last login', sortable: false, flex: 1 },
			{
				field: 'actions',
				type: 'actions',
				headerName: 'Actions',
				flex: 2,
				getActions,
			},
		],
		[getActions]
	);

	return (
		<Paper>
			<PaperTitle>Care team ({rowCount})</PaperTitle>
			<FiltersBar>
				<Box sx={{ flex: 4 }} />
				<Button onClick={() => openCareTeamMemberSaveDialog()} sx={{ alignContent: 'right' }} variant="outlined">
					Add a Care Team Member
				</Button>
			</FiltersBar>
			<DataGrid
				autoHeight
				rows={careTeamMembersData || []}
				columns={columns}
				loading={isLoading}
				rowCount={rowCount}
				pageSizeOptions={[5, 10, 25]}
				paginationModel={paginationModel}
				onPaginationModelChange={setPaginationModel}
				paginationMode="server"
				disableRowSelectionOnClick
				disableColumnFilter
				columnBufferPx={8}
			/>

			<ConfirmationDialog
				isLoading={isPendingDelete}
				title="Delete Care Team Member"
				content="Are you sure you want to delete this Care Team Member?"
				open={isOpenCareTeamMemberDeleteConfirmationDialog}
				onConfirm={() =>
					careTeamMemberDeleteMutate(careTeamMemberDeleteData, {
						onSuccess: closeCareTeamMemberDeleteConfirmationDialog,
					})
				}
				onCancel={closeCareTeamMemberDeleteConfirmationDialog}
			/>
			<Dialog
				scroll="paper"
				fullWidth
				maxWidth="md"
				open={isOpenCareTeamMemberSaveDialog}
				onClose={closeCareTeamMemberSaveDialog}
			>
				<CareTeamMemberSave {...careTeamMemberSaveData} onSaveSuccess={closeCareTeamMemberSaveDialog} />
			</Dialog>
			<Dialog
				scroll="paper"
				fullWidth
				maxWidth="md"
				open={isOpenPatientsAccessDialog}
				onClose={closePatientsAccessDialog}
			>
				<CareTeamMemberPatientsAccess {...patientsAccessData} onSaveSuccess={closePatientsAccessDialog} />
			</Dialog>
		</Paper>
	);
}

export default CareTeamMemberList;
