import React from 'react';
import DynamicForm, { IDynamicFormElement } from 'components/common/inputs/dynamicForm/DynamicForm';
import Joi from 'joi';
import { PaperTitle } from 'components/dashboardLayout';
import {
	CareTeamMember,
	CareTeamMemberSaveParams,
	CareTeamMemberViewParams,
} from 'core/api/enterprise/supervisor.models';
import { useCareTeamMemberSaveMutation, useCareTeamMemberQuery } from 'hooks';
import CircularLoading from 'components/CircularLoading';
import { IApiError } from 'core/errors/ApiError';

interface CareTeamMemberSaveProps extends Partial<CareTeamMemberViewParams> {
	onSaveSuccess: (data: CareTeamMember) => void;
}

export function CareTeamMemberSave({ id, onSaveSuccess }: CareTeamMemberSaveProps) {
	const { data: careTeamMember, isLoading } = useCareTeamMemberQuery({ id });
	const { mutate: careTeamMemberSaveMutate, isPending, error } = useCareTeamMemberSaveMutation();
	const handleCareTeamMemberSave = (props: CareTeamMemberSaveParams) => {
		careTeamMemberSaveMutate(
			{ id, ...props },
			{
				onSuccess: onSaveSuccess,
			}
		);
	};

	const formElements: Map<string, IDynamicFormElement> = new Map()
		.set('name', {
			label: 'Name',
			type: 'text',
			validation: Joi.string().required(),
			value: careTeamMember?.name || '',
		})
		.set('type', {
			label: 'Type',
			type: 'select',
			options: [
				{
					label: 'Facilitator',
					value: 1,
				},
				{
					label: 'Occupational Therapist',
					value: 2,
				},
				{
					label: 'Mental Health Professional',
					value: 3,
				},
				{
					label: 'Nurse',
					value: 4,
				},
				{
					label: 'Aide',
					value: 5,
				},
				{
					label: 'Administrative Professional',
					value: 6,
				},
				{
					label: 'Physical Therapist',
					value: 7,
				},
				{
					label: 'Health Care Professional',
					value: 8,
				},
				{
					label: 'Other',
					value: 9,
				},
			],
			validation: Joi.number().required(),
			value: careTeamMember?.type || '',
		})
		.set('phoneNumber', {
			label: 'Phone Number',
			type: 'text',
			validation: Joi.string().required(),
			value: careTeamMember?.phoneNumber || '',
		})
		.set('email', {
			label: 'Email',
			type: 'email',
			validation: Joi.string()
				.email({ tlds: { allow: false } })
				.required(),
			value: careTeamMember?.email || '',
		});

	return (
		<>
			<PaperTitle>{id ? 'Edit a care team member' : 'Create care team member'}</PaperTitle>
			{!isLoading && (
				<DynamicForm
					formElements={formElements}
					formRequestIsLoading={isPending}
					formRequestError={error as IApiError}
					onSubmitFormHandler={handleCareTeamMemberSave}
				/>
			)}
			<CircularLoading isLoading={isLoading} />
		</>
	);
}

export default CareTeamMemberSave;
