import { useMutation, useQueryClient } from '@tanstack/react-query';
import {
	userCreate,
	registerPhoneNumber as registerPhoneNumberApi,
	getVerifyIdentity,
	registerVerifyPhoneNumber,
	reset,
} from 'core/api/registration/registration.api';
import {
	ICheckStatusResponse,
	ICreateUserRequestParams,
	IRegisterPhoneNumberRequestParams,
	IVerifyIdentityResponse,
	IVerifyIdentitySearchQuery,
} from 'core/api/registration/registration.models';
import { IApiError } from 'core/errors/ApiError';

export function useRegisterMutation() {
	const queryClient = useQueryClient();

	const verifyIdentity = useMutation<
		IVerifyIdentityResponse,
		IApiError<Record<keyof IVerifyIdentitySearchQuery, string>>,
		IVerifyIdentitySearchQuery,
		unknown
	>({
		mutationFn: getVerifyIdentity,
		onSuccess: () => {
			queryClient.invalidateQueries({ queryKey: ['register', 'initialData'] });
		},
	});

	const createUser = useMutation<
		string,
		IApiError<Record<keyof ICreateUserRequestParams, string>>,
		ICreateUserRequestParams,
		unknown
	>({
		mutationFn: userCreate,
		onSuccess: () => {
			queryClient.invalidateQueries({ queryKey: ['register', 'initialData'] });
		},
	});

	const registerPhoneNumber = useMutation<
		string,
		IApiError<Record<keyof IRegisterPhoneNumberRequestParams, string>>,
		IRegisterPhoneNumberRequestParams,
		unknown
	>({
		mutationFn: registerPhoneNumberApi,
		onSuccess: (data, variables) => {
			const initialData: ICheckStatusResponse | undefined = queryClient.getQueryData(['register', 'initialData']);

			if (initialData) {
				queryClient.setQueryData(['register', 'initialData'], { ...initialData, phoneNumber: variables.phoneNumber });
			}
		},
	});

	const verifyPhoneNumber = useMutation({
		mutationFn: registerVerifyPhoneNumber,
	});

	const resetRegister = useMutation({
		mutationFn: reset,
		onSettled: () => {
			queryClient.invalidateQueries({ queryKey: ['register', 'initialData'] });
		},
	});

	return {
		verifyIdentity,
		createUser,
		registerPhoneNumber,
		verifyPhoneNumber,
		resetRegister,
	};
}

export default useRegisterMutation;
