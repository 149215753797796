import { endpoints } from 'core/api/endpoints';
import { deleteRequest, getRequest, patchRequest, postRequest } from 'core/api/api-service';
import {
	ICareTeamMember,
	ICreateCareTeamMemberResponse,
	IEnterprise,
	IEnterpriseLocation,
	IEnterprisePatient,
	IEnterprisesList,
	IUpdateCareTeamMemberResponse,
} from 'core/models/admin/enterprise.models';
import {
	ICareTeamMemberRequestActionPayload,
	ICareTeamMemberResponse,
	ICareTeamMembersListRequest,
	ICareTeamMembersListResponse,
	ICreateCareTeamMemberRequestActionPayload,
	IEnterpriseRequest,
	IEnterpriseResponse,
	IEnterprisesListRequest,
	IEnterprisesListResponse,
	IUpdateCareTeamMemberRequestActionPayload,
	IUpdateEnterpriseRequest,
	IDeleteEnterpriseRequest,
	IDeleteCareTeamMemberRequest,
} from './enterprise.models';
import { UserLoginAs, UserLoginAsParams } from './patients.models';

function mapEnterprisesList(result: IEnterprisesListResponse): IEnterprisesList {
	const enterprises = result.member.map((enterprise) => {
		return {
			id: enterprise.id,
			sfid: enterprise.sfid,
			name: enterprise.name,
		};
	});

	const total = result.totalItems;

	return { enterprises, total };
}

export async function getEnterprisesList(request: IEnterprisesListRequest): Promise<IEnterprisesList> {
	const body = {
		page: request.page,
		itemsPerPage: request.itemsPerPage,
		name: request?.name || '',
	};

	const result = await getRequest<IEnterprisesListResponse>(endpoints.admin.enterprise.list, body, {});

	return mapEnterprisesList(result);
}

export function getEnterprisesListN({
	name,
	...restRequest
}: IEnterprisesListRequest): Promise<IEnterprisesListResponse> {
	const body = {
		...restRequest,
		name: name || '',
	};

	return getRequest<IEnterprisesListResponse>(endpoints.admin.enterprise.list, body, {});
}

export async function getEnterprise(request: IEnterpriseRequest): Promise<IEnterprise> {
	const result = await getRequest<IEnterpriseResponse>(endpoints.admin.enterprise.patientAccesses(request.id), {}, {});

	const reduceToRecord = <T>(acc: Record<string, T[]>, { label, options }: { label: string | null; options: T[] }) => {
		if (label) {
			// eslint-disable-next-line no-param-reassign
			acc[label] = options as T[];
		}
		return acc;
	};

	return {
		id: parseInt(request.id, 10),
		name: result.enterprise.name,
		sfid: result.enterprise.sfid,
		states: result.states.map((state) => {
			return {
				sfid: state.sfid,
				name: state.name,
			};
		}),
		locations: result.locations.reduce(
			(acc, location) => reduceToRecord(acc, location),
			{} as Record<string, IEnterpriseLocation[]>
		),
		patients: result.patients.reduce(
			(acc, patient) => reduceToRecord(acc, patient),
			{} as Record<string, IEnterprisePatient[]>
		),
	};
}

export async function updateEnterprise(request: IUpdateEnterpriseRequest): Promise<void> {
	const body = {
		name: request.name,
	};

	await patchRequest<void>(endpoints.admin.enterprise.updateEnterprise(request.id), body, {});
}

export async function deleteEnterprise(request: IDeleteEnterpriseRequest): Promise<void> {
	await deleteRequest<void>(endpoints.admin.enterprise.deleteEnterprise(request.id), {}, {});
}

export async function getCareTeamMembersList({
	name,
	...restRequest
}: ICareTeamMembersListRequest): Promise<ICareTeamMembersListResponse> {
	const body = {
		...restRequest,
		name: name || '',
	};

	return getRequest<ICareTeamMembersListResponse>(endpoints.admin.enterprise.careTeamMembers.list, body, {});
}

export function createCareTeamMember(
	request: ICreateCareTeamMemberRequestActionPayload
): Promise<ICreateCareTeamMemberResponse> {
	const body = {
		enterpriseId: request.enterpriseId,
		email: request.email,
		name: request.name,
		supervisor: request.supervisor,
		status: request.status,
		patientsAccessAll: request.patientsAccessAll,
		patientsAccessStateSfid: request.patientsAccessStateSfid,
		patientsAccessLocationSfid: request.patientsAccessLocationSfid,
		patientsAccessIDs: request.patientsAccessIDs,
	};
	return postRequest<ICreateCareTeamMemberResponse>(endpoints.admin.enterprise.careTeamMembers.create, body, {});
}

export function updateCareTeamMember(
	request: IUpdateCareTeamMemberRequestActionPayload
): Promise<IUpdateCareTeamMemberResponse> {
	const body = {
		email: request.email,
		name: request.name,
		supervisor: request.supervisor,
		status: request.status,
		patientsAccessAll: request.patientsAccessAll,
		patientsAccessStateSfid: request.patientsAccessStateSfid,
		patientsAccessLocationSfid: request.patientsAccessLocationSfid,
		patientsAccessIDs: request.patientsAccessIDs,
	};
	return patchRequest<IUpdateCareTeamMemberResponse>(
		endpoints.admin.enterprise.careTeamMembers.update(request.id),
		body,
		{}
	);
}

export async function getCareTeamMember(request: ICareTeamMemberRequestActionPayload): Promise<ICareTeamMember> {
	const result = await getRequest<ICareTeamMemberResponse>(
		endpoints.admin.enterprise.careTeamMembers.get(request.id),
		{},
		{}
	);

	return {
		id: result.id,
		enterpriseId: result.enterpriseId,
		email: result.email,
		name: result.name,
		supervisor: result.supervisor,
		status: result.status,
		patientsAccessAll: result.patientsAccessAll,
		patientsAccessStateSfid: result.patientsAccessStateSfid,
		patientsAccessLocationSfid: result.patientsAccessLocationSfid,
		patientsAccessIDs: result.patientsAccessIDs,
		phone: result.phone,
		lastUpdated: result.lastUpdated,
	};
}

export async function deleteCareTeamMember(request: IDeleteCareTeamMemberRequest): Promise<void> {
	await deleteRequest<void>(endpoints.admin.enterprise.careTeamMembers.delete(request.id), {}, {});
}

export async function loginAsCareTeamMember(request: UserLoginAsParams): Promise<UserLoginAs> {
	return getRequest<UserLoginAs>(endpoints.admin.enterprise.careTeamMembers.loginAs(request.id), {}, {});
}
