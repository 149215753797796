import React, { useCallback, useState, useRef, useMemo } from 'react';
import { Grid } from '@mui/material';
import { GridActionsCellItem, GridColDef } from '@mui/x-data-grid';
import DeleteIcon from '@mui/icons-material/Delete';
import { PaperTitle, Paper } from 'components/dashboardLayout';
import { FiltersBar, SearchFilter } from 'components/common/inputs/filters';
import DataGrid from 'components/dataGrid';
import ConfirmationDialog from 'components/confirmationDialog';
import { useDialog } from 'hooks';
import withPageContext from 'hoc/withPageContext';
import { useLoginAttempt } from 'hooks/admin/useLoginAttempt';

function BlockedLoginsPage() {
	const { loginAttemptListQuery, deleteLoginAttemptMutation } = useLoginAttempt();
	const [paginationModel, setPaginationModel] = useState({
		page: 0,
		pageSize: 25,
	});
	const [username, setUsername] = useState<string | undefined>(undefined);

	const { data: admins, isLoading } = loginAttemptListQuery({
		username,
		page: paginationModel.page + 1,
		itemsPerPage: paginationModel.pageSize,
	});

	const rowCountRef = useRef(admins?.totalItems || 0);
	const rowCount = useMemo(() => {
		if (admins?.totalItems !== undefined) {
			rowCountRef.current = admins.totalItems;
		}
		return rowCountRef.current;
	}, [admins?.totalItems]);

	const [
		isOpenDeleteConfirmationDialog,
		openDeleteConfirmationDialog,
		closeDeleteConfirmationDialog,
		blockedLoginIdToDelete,
	] = useDialog<number>();

	const handleDeleteBlockedLogin = useCallback(() => {
		if (blockedLoginIdToDelete) {
			deleteLoginAttemptMutation.mutate(
				{ id: blockedLoginIdToDelete.toString() },
				{ onSuccess: closeDeleteConfirmationDialog }
			);
		}
	}, [blockedLoginIdToDelete]);

	const columns: GridColDef[] = [
		{ field: 'id', headerName: 'ID', sortable: false, flex: 1 },
		{
			field: 'username',
			headerName: 'Username',
			sortable: false,
			flex: 4,
			editable: true,
		},
		{
			field: 'updatedAt',
			headerName: 'Updated at',
			sortable: false,
			flex: 2,
		},
		{
			field: 'actions',
			type: 'actions',
			headerName: 'Actions',
			cellClassName: 'actions',
			getActions: ({ row }) => {
				return [
					<GridActionsCellItem
						icon={<DeleteIcon />}
						label="Delete"
						title="Delete"
						color="inherit"
						onClick={() => openDeleteConfirmationDialog(row.id)}
					/>,
				];
			},
		},
	];

	const handleClearFilters = () => {
		setUsername(undefined);
	};

	return (
		<Grid item xs={12}>
			<Paper>
				<PaperTitle>Blocked Logins</PaperTitle>
				<FiltersBar onClearFilters={handleClearFilters} buttonProps={{ variant: 'outlined' }}>
					<SearchFilter
						sx={{ flex: 4 }}
						name="search"
						label="Search username"
						value={username}
						size="small"
						variant="outlined"
						onChange={(event) => setUsername(event.target.value)}
					/>
				</FiltersBar>
				<DataGrid
					autoHeight
					rows={admins?.member || []}
					columns={columns}
					loading={isLoading}
					rowCount={rowCount}
					pageSizeOptions={[5, 10, 25]}
					paginationModel={paginationModel}
					paginationMode="server"
					onPaginationModelChange={setPaginationModel}
					editMode="row"
					disableRowSelectionOnClick
					disableColumnSelector
					disableColumnMenu
					disableColumnFilter
				/>
				<ConfirmationDialog
					isLoading={deleteLoginAttemptMutation.isPending}
					title="Delete Blocked Login"
					content="Are you sure you want to delete?"
					open={isOpenDeleteConfirmationDialog}
					onConfirm={handleDeleteBlockedLogin}
					onCancel={closeDeleteConfirmationDialog}
				/>
			</Paper>
		</Grid>
	);
}

export default withPageContext(BlockedLoginsPage);
