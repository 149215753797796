import React, { useMemo } from 'react';
import { MedScheduleDetails } from 'components/patient/mySchedule';
import { generatePath, useNavigate, useParams } from 'react-router-dom';
import dayjs from 'dayjs';
import Dialog from 'components/dialog';
import routes from 'routes';
import {
	useAsNeededMedListQuery,
	useDeleteTakeAsNeededMedMutation,
	useScheduledMedsQuery,
	useTakeAsNeededMedMutation,
	useTakeMedMutation,
} from 'hooks/patient';
import { Stack, Typography } from '@mui/material';
import { AsNeededMedByDayList, MedScheduleListByDay } from 'components/common/mySchedule';
import { MedScheduleListByDayLoading } from 'components/common/mySchedule/MedScheduleListByDay';
import Suspense from 'components/Suspense';
import { IScheduledMedDetails } from 'core/api/mySchedule/mySchedule.models';

export default function SchedulerDayDetailsPage() {
	const navigate = useNavigate();
	const { date, id } = useParams();
	const daySelected = dayjs(date);
	const scheduleId = id ? Number(id) : undefined;

	const { data: scheduledMedsData, isLoading: isLoadingScheduledMedsData } = useScheduledMedsQuery({
		day: daySelected.format('YYYY-MM-DD'),
	});
	const { mutate: onLogChange, isPending } = useTakeMedMutation();

	const { data: asNeededMedData, isLoading: isLoadingAsNeededMedData } = useAsNeededMedListQuery({
		day: daySelected.format('YYYY-MM-DD'),
	});
	const { mutate: onTakeAsNeededMed } = useTakeAsNeededMedMutation();
	const { mutate: onDeleteTakeAsNeededMed } = useDeleteTakeAsNeededMedMutation();

	const handleShowScheduleDetails = (scheduleDetails: IScheduledMedDetails) =>
		navigate(
			generatePath(routes.mySchedule.reviewMyHistory.day, {
				date: daySelected.format('YYYY-MM-DD'),
				id: scheduleDetails.id,
			})
		);

	const handleCloseScheduleDetails = () =>
		navigate(
			generatePath(routes.mySchedule.reviewMyHistory.day, {
				date: daySelected.format('YYYY-MM-DD'),
			})
		);

	const scheduleDetails = useMemo(
		() => scheduledMedsData?.member?.find((item) => item.id === scheduleId),
		[scheduledMedsData, scheduleId]
	);

	return (
		<Dialog
			title={scheduleDetails ? `${scheduleDetails?.title} Medication` : dayjs(date).format('MMMM D, YYYY')}
			scroll="paper"
			fullWidth
			maxWidth="md"
			open
			onClose={() => navigate(routes.mySchedule.reviewMyHistory.calendar)}
		>
			{!scheduleDetails && (
				<Stack useFlexGap spacing={2} direction="column">
					<Suspense fallback={<MedScheduleListByDayLoading />} isLoading={isLoadingScheduledMedsData}>
						<MedScheduleListByDay
							data={scheduledMedsData}
							onShowScheduleDetails={handleShowScheduleDetails}
							{...{ onLogChange, isPending }}
						/>
					</Suspense>
					<Stack>
						<Typography variant="h3" fontWeight="bold" mb={1}>
							As-Needed Medication
						</Typography>
						<Suspense isLoading={isLoadingAsNeededMedData}>
							<AsNeededMedByDayList
								data={asNeededMedData}
								{...{ daySelected, onTakeAsNeededMed, onDeleteTakeAsNeededMed }}
							/>
						</Suspense>
					</Stack>
				</Stack>
			)}

			{scheduleDetails && (
				<MedScheduleDetails {...scheduleDetails} onLogChange={onLogChange} onBackButton={handleCloseScheduleDetails} />
			)}
		</Dialog>
	);
}
