import { getBlobRequest, getRequest, postRequest } from '../api-service';
import { endpoints } from '../endpoints';
import {
	IGetMedicationsResponse,
	IGetMedicationSummaryRequestParams,
	IGetMedicationSummaryResponse,
	IGetRefillRequestFormPdfRequestParams,
	IMedication,
	IMedsGrouped,
	IRequestRefillParams,
	MedicationOnboardingAlert,
} from './medications.models';
import { ISuccessResponse } from '../login/login.models';

export function medsGroupedByPacks(result: IMedication[]): IMedsGrouped {
	return result.reduce((acc, medication) => {
		const { pack } = medication;

		if (pack) {
			return {
				...acc,
				[pack]: [...(acc[pack] || []), medication],
			};
		}

		return acc;
	}, {} as IMedsGrouped);
}

export function medsGroupedByAlerts(result: IMedication[]): IMedsGrouped {
	return result.reduce((acc, medication) => {
		const { refillAlert } = medication;

		if (refillAlert) {
			return {
				...acc,
				[refillAlert]: [...(acc[refillAlert] || []), medication],
			};
		}

		return acc;
	}, {} as IMedsGrouped);
}

export function mapMedicationOnboardingAlert(onboardingAlert: string): string | null {
	const onboardingAlertMap = {
		[MedicationOnboardingAlert.REFILL_TOO_SOON]:
			'This medication is not due for a refill. Our pharmacy will refill it when it’s due. Call if you need it sooner at 1-877-355-7225.',
		[MedicationOnboardingAlert.VERIFY_IF_PATIENT_TAKING_MED]:
			'We need to confirm you’re still taking this medication. Please call us to review your medications at 1-877-355-7225.',
		[MedicationOnboardingAlert.DRUG_NOT_COVERED]:
			'Your insurance does not cover this medication. Please call us to review your medications at 1-877-355-7225.',
	};

	return onboardingAlertMap[onboardingAlert as MedicationOnboardingAlert] || null;
}

export function getMedications(): Promise<IGetMedicationsResponse> {
	return getRequest<IGetMedicationsResponse>(endpoints.medications.list, {}, {});
}

export function getPdfMedications(): Promise<Blob> {
	return getBlobRequest(endpoints.medications.listPdfDownload, {}, {});
}

export function getRefillRequestFormPdf(request: IGetRefillRequestFormPdfRequestParams): Promise<Blob> {
	const body = {
		prescriberSfid: request.prescriberSfid,
		currentMedicationSfid: request.currentMedicationSfid || '',
	};

	return getBlobRequest(endpoints.medications.refillRequestFormPdfDownload, body, {});
}

export function getMedicationSummary(
	request: IGetMedicationSummaryRequestParams
): Promise<IGetMedicationSummaryResponse> {
	const body = {
		sfid: request.sfid,
	};

	return getRequest<IGetMedicationSummaryResponse>(endpoints.medications.summary, body, {});
}

export function requestRefill(request: IRequestRefillParams): Promise<ISuccessResponse> {
	const body = {
		sfid: request.sfid,
	};

	return postRequest<ISuccessResponse>(endpoints.medications.requestRefill, body, {});
}
