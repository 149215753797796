export const LEGAL_URLS = {
	TERMS_AND_CONDITIONS: 'https://anewhealthrx.com/app-terms-of-use/',
	PRIVACY_POLICY: 'https://anewhealthrx.com/privacy-statement/  ',
} as const;

export const SUPPORT_URLS = {
	HELP_CENTER: 'https://support.anewhealthrx.com/',
	HOW_TO_USE: 'https://www.exactcarepharmacy.com/help-with-my-medications/welcome-new-exactcare-patient/#how-to',
	PAYMENT: 'https://www.exactcarepharmacy.com/about-exactcare/payment/',
	ASK_A_PHARMACIST: 'https://www.exactcarepharmacy.com/help-with-my-medications/rxhelp/ask-a-pharmacist-december-2022/',
	TEXT_MESSAGES:
		'https://www.exactcarepharmacy.com/text-messages/?utm_campaign=Twilio&utm_medium=Clicked-Link&utm_source=APP',
} as const;
