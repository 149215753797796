import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

export function useRegisterHash() {
	const [hash, setHash] = useState<string>(() => localStorage.getItem('register') || '');
	const params = useParams<{ hash?: string }>();

	useEffect(() => {
		if (hash && hash.length > 0) {
			localStorage.setItem('register', hash);
		} else {
			localStorage.removeItem('register');
		}
	}, [hash]);

	useEffect(() => {
		const hashFromParams: string | null = params?.hash || null;

		if (hashFromParams && hashFromParams !== hash) {
			setHash(hashFromParams);
		}
	}, [params?.hash, hash]);

	return { hash, setHash };
}

export default useRegisterHash;
