import React, { useCallback, useMemo, useRef, useState } from 'react';
import { PaperTitle, Paper } from 'components/dashboardLayout';
import { Link, Grid, IconButton } from '@mui/material';
import routes from 'routes';
import { generatePath, Link as RouterLink } from 'react-router-dom';
import { DateFilter, FiltersBar, SelectFilter, SearchFilter } from 'components/common/inputs/filters';
import DataGrid from 'components/dataGrid';
import {
	GridColDef,
	GridActionsCellItem,
	GridRowModesModel,
	GridRowId,
	GridRowModes,
	GridEventListener,
	GridRowEditStopReasons,
	GridRowModel,
} from '@mui/x-data-grid';
import { PatientStatus } from 'core/models/admin/patients.models';
import { IChangePasswordPatientRequest, IPatientResponse } from 'core/api/admin/patients.models';
import dayjs from 'dayjs';
import PatientStatusChip from 'components/patientStatusChip/PatientStatusChip';
import ConfirmationDialog from 'components/confirmationDialog';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import LockResetIcon from '@mui/icons-material/LockReset';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import DoDisturbOnIcon from '@mui/icons-material/DoDisturbOn';
import DoDisturbOffIcon from '@mui/icons-material/DoDisturbOff';
import SaveIcon from '@mui/icons-material/Save';
import LoginIcon from '@mui/icons-material/Login';
import { useAuth, useDialog } from 'hooks';
import { usePatientActions } from 'hooks/admin';
import Dialog from 'components/dialog';
import { PatientChangePassword } from 'components/admin';
import withPageContext from 'hoc/withPageContext';

function PatientsPage() {
	const {
		patientsListQuery,
		suspendPatientMutation,
		unblockPatientMutation,
		deletePatientMutation,
		changeEmailMutation,
		changeDateOfBirthMutation,
		changePatientPasswordMutation,
	} = usePatientActions();

	const [paginationModel, setPaginationModel] = useState({
		page: 0,
		pageSize: 25,
	});
	const [status, setStatus] = useState<PatientStatus | undefined>(undefined);
	const [search, setSearch] = useState<string | undefined>(undefined);
	const [dob, setDob] = useState<string>('');
	const [
		isOpenDeleteConfirmationDialog,
		openDeleteConfirmationDialog,
		closeDeleteConfirmationDialog,
		patientIdToDelete,
	] = useDialog<number>();
	const [
		isOpenSuspendConfirmationDialog,
		openSuspendConfirmationDialog,
		closeSuspendConfirmationDialog,
		patientToSuspend,
	] = useDialog<IPatientResponse>();
	const [isOpenChangePasswordDialog, openChangePasswordDialog, closeChangePasswordDialog, patientIdToChangePassword] =
		useDialog<number>();

	const [rowModesModel, setRowModesModel] = useState<GridRowModesModel>({});
	const { data: patients, isLoading } = patientsListQuery({
		status,
		search,
		dob,
		page: paginationModel.page + 1,
		itemsPerPage: paginationModel.pageSize,
	});

	const rowCountRef = useRef(patients?.totalItems || 0);
	const rowCount = useMemo(() => {
		if (patients?.totalItems !== undefined) {
			rowCountRef.current = patients.totalItems;
		}
		return rowCountRef.current;
	}, [patients?.totalItems]);

	const { switchToPatient } = useAuth();
	const { mutate: switchUserMutate, isPending: isPendingSwitchUser } = switchToPatient;

	const handleRowModesModelChange = (newRowModesModel: GridRowModesModel) => {
		setRowModesModel(newRowModesModel);
	};

	const handleClearFilters = useCallback(() => {
		setStatus(undefined);
		setSearch(undefined);
		setDob('');
	}, []);

	const handleDeletePatient = useCallback(() => {
		if (patientIdToDelete) {
			deletePatientMutation.mutate(
				{ userId: patientIdToDelete.toString() },
				{ onSuccess: closeDeleteConfirmationDialog }
			);
		}
	}, [patientIdToDelete]);

	const handleSuspendPatient = useCallback(() => {
		if (patientToSuspend?.userId) {
			suspendPatientMutation.mutate(
				{ userId: patientToSuspend.userId.toString(), suspend: patientToSuspend.status !== PatientStatus.SUSPENDED },
				{
					onSuccess: () => {
						closeSuspendConfirmationDialog();
					},
				}
			);
		}
	}, [patientToSuspend]);

	const handleChangePatientPassword = useCallback((props: IChangePasswordPatientRequest) => {
		changePatientPasswordMutation.mutate(props, { onSuccess: closeChangePasswordDialog });
	}, []);

	const handleEditClick = useCallback(
		(id: GridRowId) => {
			setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
		},
		[rowModesModel]
	);

	const handleSaveClick = useCallback(
		(id: GridRowId) => {
			setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
		},
		[rowModesModel]
	);

	const handleRowEditStop: GridEventListener<'rowEditStop'> = useCallback((params, event) => {
		if (event && params.reason === GridRowEditStopReasons.rowFocusOut) {
			// eslint-disable-next-line no-param-reassign
			event.defaultMuiPrevented = true;
		}
	}, []);

	const handleProcessRowUpdate = useCallback((newRow: GridRowModel, originalRow: GridRowModel) => {
		if (newRow?.userId && newRow?.email && newRow?.email !== originalRow?.email) {
			changeEmailMutation.mutate({ userId: newRow.userId, email: newRow.email });
		}

		if (newRow?.userId && newRow?.dateOfBirth !== originalRow?.dateOfBirth) {
			changeDateOfBirthMutation.mutate({
				userId: newRow.userId,
				dateOfBirth: newRow.dateOfBirth ? dayjs(newRow.dateOfBirth).format('YYYY-MM-DD') : null,
			});
		}

		return newRow;
	}, []);

	const unblockButton = useCallback(
		(userId: number) => (
			<IconButton
				color="inherit"
				aria-label="Unblock"
				title="Unblock"
				sx={{ mr: 1 }}
				size="small"
				onClick={() => unblockPatientMutation.mutate({ userId: userId.toString() })}
			>
				<LockOpenIcon fontSize="small" />
			</IconButton>
		),
		[unblockPatientMutation]
	);

	const formatDOB = (data: { row: IPatientResponse }) => {
		if (!data || !data.row) {
			return null;
		}

		const { row } = data;
		return row.dateOfBirth ? new Date(row.dateOfBirth) : null;
	};

	const columns = useMemo<GridColDef[]>(
		() => [
			{ field: 'userId', headerName: 'ID', sortable: false, flex: 2, type: 'number' },
			{
				field: 'fullName',
				headerName: 'Full name',
				sortable: false,
				flex: 4,
				renderCell: ({ row, value }) => (
					<>
						{row.status === PatientStatus.BLOCKED && unblockButton(row.userId as number)}
						<Link
							component={RouterLink}
							to={generatePath(routes.admin.patients.patient.medSync, {
								patientId: row.userId,
							})}
							fontWeight="bold"
						>
							{value || `Patient #${row.userId}`}
						</Link>
					</>
				),
			},
			{
				field: 'dateOfBirth',
				headerName: 'DOB',
				sortable: false,
				flex: 2,
				type: 'date',
				editable: true,
				valueGetter: formatDOB,
			},
			{ field: 'email', headerName: 'Email', sortable: false, flex: 4, editable: true },
			{ field: 'phone', headerName: 'Phone', sortable: false, flex: 2 },
			{ field: 'createdAt', headerName: 'Created At', sortable: false, flex: 3 },
			{ field: 'lastLoginAt', headerName: 'Last login', sortable: false, flex: 3 },
			{
				field: 'status',
				headerName: 'Status',
				sortable: false,
				flex: 2,
				renderCell: ({ value }) => <PatientStatusChip status={value} />,
			},
			{
				field: 'actions',
				type: 'actions',
				headerName: 'Actions',
				flex: 4,
				minWidth: 200,
				getActions: ({ id, row }) => {
					const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

					const editAction = isInEditMode ? (
						<GridActionsCellItem
							data-testid="save-button"
							icon={<SaveIcon />}
							title="Save"
							label="Save"
							sx={{
								color: 'primary.main',
							}}
							onClick={() => handleSaveClick(id)}
						/>
					) : (
						<GridActionsCellItem
							data-testid="edit-button"
							icon={<EditIcon />}
							title="Edit"
							label="Edit"
							className="textPrimary"
							color="inherit"
							onClick={() => handleEditClick(id)}
						/>
					);

					return [
						<GridActionsCellItem
							icon={<LoginIcon />}
							title="Log in as"
							label="Log in as"
							color="inherit"
							disabled={isPendingSwitchUser}
							onClick={() => switchUserMutate({ id: row.userId })}
						/>,
						editAction,
						<GridActionsCellItem
							data-testid="suspend-button"
							disabled={row.status === PatientStatus.DELETED}
							icon={row.status === PatientStatus.SUSPENDED ? <DoDisturbOffIcon /> : <DoDisturbOnIcon />}
							title={row.status === PatientStatus.SUSPENDED ? 'Unsuspend' : 'Suspend'}
							label={row.status === PatientStatus.SUSPENDED ? 'Unsuspend' : 'Suspend'}
							color="inherit"
							onClick={() => openSuspendConfirmationDialog(row)}
						/>,
						<GridActionsCellItem
							icon={<LockResetIcon />}
							title="Change Password"
							label="Change Password"
							color="inherit"
							onClick={() => openChangePasswordDialog(row.userId)}
						/>,
						<GridActionsCellItem
							icon={<DeleteIcon />}
							title="Delete"
							label="Delete"
							color="inherit"
							disabled={row.status === PatientStatus.DELETED}
							onClick={() => openDeleteConfirmationDialog(row.userId)}
						/>,
					];
				},
			},
		],
		[unblockButton, rowModesModel]
	);

	return (
		<Grid item xs={12}>
			<Paper>
				<PaperTitle>Patients list</PaperTitle>
				<FiltersBar onClearFilters={handleClearFilters} buttonProps={{ variant: 'outlined' }}>
					<SelectFilter
						sx={{ flex: 1 }}
						name="status"
						label="Status"
						value={status}
						placeholder="All"
						items={Object.values(PatientStatus).map((patientStatus) => ({
							value: patientStatus,
							label: patientStatus,
						}))}
						onChange={(value) => {
							setStatus(value as PatientStatus | undefined);
						}}
					/>
					<SearchFilter
						sx={{ flex: 4 }}
						name="search"
						label="Search by name, email, phone and GUID"
						value={search}
						onChange={(event) => setSearch(event.target.value)}
						variant="outlined"
						size="small"
					/>
					<DateFilter
						name="dob"
						label="Search patient by DOB"
						sx={{ flex: 2 }}
						value={dob ? dayjs(dob) : null}
						onChange={(value) => {
							setDob(value?.format('MM/DD/YYYY') || '');
						}}
					/>
				</FiltersBar>
				<DataGrid
					autoHeight
					rows={patients?.member || []}
					columns={columns}
					loading={isLoading}
					rowCount={rowCount}
					pageSizeOptions={[5, 10, 25]}
					paginationModel={paginationModel}
					paginationMode="server"
					onPaginationModelChange={setPaginationModel}
					getRowId={(row) => row.userId}
					disableRowSelectionOnClick
					disableColumnSelector
					disableColumnMenu
					disableColumnFilter
					columnBufferPx={8}
					getRowClassName={(params) => `row-status--${params.row.status}`}
					editMode="row"
					rowModesModel={rowModesModel}
					onRowModesModelChange={handleRowModesModelChange}
					onRowEditStop={handleRowEditStop}
					processRowUpdate={handleProcessRowUpdate}
				/>
				<ConfirmationDialog
					isLoading={deletePatientMutation.isPending}
					title="Delete Patient"
					content="Are you sure you want to delete this patient?"
					open={isOpenDeleteConfirmationDialog}
					onConfirm={handleDeletePatient}
					onCancel={closeDeleteConfirmationDialog}
				/>
				<ConfirmationDialog
					isLoading={suspendPatientMutation.isPending}
					title={patientToSuspend?.status === PatientStatus.SUSPENDED ? 'Unsuspend Patient' : 'Suspend Patient'}
					content={
						patientToSuspend?.status === PatientStatus.SUSPENDED
							? 'Are you sure you want to unsuspend this patient?'
							: 'Are you sure you want to suspend this patient?'
					}
					open={isOpenSuspendConfirmationDialog}
					onConfirm={handleSuspendPatient}
					onCancel={closeSuspendConfirmationDialog}
				/>
				<Dialog
					open={isOpenChangePasswordDialog}
					scroll="paper"
					fullWidth
					maxWidth="xs"
					title="Change Patient's Password"
					onClose={closeChangePasswordDialog}
				>
					<PatientChangePassword
						isLoading={changePatientPasswordMutation.isPending}
						onSubmit={handleChangePatientPassword}
						userId={patientIdToChangePassword?.toString()}
					/>
				</Dialog>
			</Paper>
		</Grid>
	);
}

export default withPageContext(PatientsPage);
