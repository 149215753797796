import React, { useCallback, useMemo, useRef, useState } from 'react';
import { Paper, PaperTitle } from 'components/dashboardLayout';
import DataGrid from 'components/dataGrid';
import { GridColDef, GridSortModel } from '@mui/x-data-grid';
import { useMedsProcessingJobsQuery } from 'hooks/admin';
import StatusChip, { StatusChipLegend } from 'components/StatusChip';
import { MedsProcessingJobStatus, PatientDetails } from 'core/api/admin/patients.models';
import Markdown from 'components/Markdown/Markdown';
import Dialog from 'components/dialog';
import { Button, Stack } from '@mui/material';
import { useDisclosure } from 'hooks';
import { PatientMedSyncSave } from 'components/admin';

interface PatientMedSyncListProps extends Pick<PatientDetails, 'userId'> {}

export function PatientMedSyncList({ userId }: PatientMedSyncListProps) {
	const [paginationModel, setPaginationModel] = useState({
		page: 0,
		pageSize: 10,
	});
	const [sortModel, setSortModel] = useState<{
		field: 'id' | 'status' | 'createdAt' | 'updatedAt';
		sort: 'ASC' | 'DESC';
	}>({
		field: 'id',
		sort: 'DESC',
	});

	const { data, isLoading } = useMedsProcessingJobsQuery({
		patientId: userId.toString(),
		page: paginationModel.page + 1,
		itemsPerPage: paginationModel.pageSize,
		orderBy: sortModel.field,
		orderDirection: sortModel.sort,
	});
	const { member: medSyncData, totalItems: totalMedSync } = data ?? {};

	const rowCountRef = useRef(totalMedSync || 0);
	const rowCount = useMemo(() => {
		if (totalMedSync !== undefined) {
			rowCountRef.current = totalMedSync;
		}
		return rowCountRef.current;
	}, [totalMedSync]);

	const medSyncStatusLegend = useMemo<StatusChipLegend[]>(
		() => [
			{
				id: MedsProcessingJobStatus.PENDING,
				label: 'Pending',
				color: 'default',
			},
			{
				id: MedsProcessingJobStatus.PROCESSING,
				label: 'Processing',
				color: 'primary',
			},
			{
				id: MedsProcessingJobStatus.SUCCESS,
				label: 'Success',
				color: 'success',
			},
			{
				id: MedsProcessingJobStatus.ERROR,
				label: 'Error',
				color: 'error',
			},
		],
		[]
	);

	const columns = useMemo<GridColDef[]>(
		() => [
			{ field: 'id', headerName: 'ID', sortable: true, flex: 1 },
			{
				field: 'triggerType',
				headerName: 'Trigger Type',
				sortable: false,
				flex: 2,
			},
			{
				field: 'notes',
				headerName: 'Notes',
				sortable: false,
				flex: 2,
				renderCell: ({ value }) => <Markdown>{value}</Markdown>,
			},
			{ field: 'from', headerName: 'Date from', sortable: false, flex: 2 },
			{ field: 'to', headerName: 'Date to', sortable: false, flex: 2 },
			{
				field: 'status',
				headerName: 'Status',
				sortable: true,
				flex: 1,
				renderCell: ({ value }) => <StatusChip legend={medSyncStatusLegend} statusId={value} />,
			},
			{ field: 'createdAt', headerName: 'Created at', sortable: true, flex: 1 },
			{ field: 'updatedAt', headerName: 'Updated at', sortable: true, flex: 1 },
		],
		[]
	);

	const {
		isOpen: isOpenAddPatientMedSyncDialog,
		onOpen: onOpenAddPatientMedSyncDialog,
		onClose: onCloseAddPatientMedSyncDialog,
	} = useDisclosure();

	const handleSortModelChange = useCallback((gridSortModel: GridSortModel) => {
		const sort = gridSortModel[0]?.sort?.toUpperCase() as 'ASC' | 'DESC';
		const field = gridSortModel[0]?.field as 'id' | 'status' | 'createdAt' | 'updatedAt';

		setSortModel({
			field,
			sort,
		});
	}, []);

	return (
		<Paper>
			<Stack direction="row" justifyContent="space-between" alignItems="flex-start">
				<PaperTitle>Med Sync</PaperTitle>
				<Button onClick={onOpenAddPatientMedSyncDialog} variant="outlined">
					Add Med Sync
				</Button>
			</Stack>
			<DataGrid
				autoHeight
				rows={medSyncData || []}
				columns={columns}
				loading={isLoading}
				rowCount={rowCount}
				pageSizeOptions={[5, 10, 25]}
				paginationModel={paginationModel}
				onPaginationModelChange={setPaginationModel}
				paginationMode="server"
				sortingMode="server"
				onSortModelChange={handleSortModelChange}
				disableRowSelectionOnClick
				disableColumnFilter
				columnBufferPx={8}
				getRowClassName={(params) => `row-status--${params.row.status}`}
			/>

			<Dialog
				scroll="paper"
				fullWidth
				maxWidth="xs"
				open={isOpenAddPatientMedSyncDialog}
				onClose={onCloseAddPatientMedSyncDialog}
			>
				<PatientMedSyncSave userId={userId} onSaveSuccess={onCloseAddPatientMedSyncDialog} />
			</Dialog>
		</Paper>
	);
}

export default PatientMedSyncList;
